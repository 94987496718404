import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { deleteLesson, fetchLessons } from '../../actions';
import NotFound from '../../components/NotFound';

class LessonDeleteScreen extends Component {
  componentDidMount() {
    const { courseId } = this.props.match.params;
    this.props.fetchLessons(courseId);
  }
  onSubmit = () => {
    const { lessonId, courseId } = this.props.match.params;
    const currentLesson = this.props.lessons[lessonId];

    this.props.deleteLesson(courseId, currentLesson.lessonId, currentLesson.fileNames);
  };
  render() {
    if (!this.props.isAdmin) {
      return <NotFound />;
    } else {
      return (
        <div style={{ textAlign: 'center' }}>
          <Button variant="contained" color="primary" onClick={this.onSubmit}>
            Delete
          </Button>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return { isAdmin: state.auth.isAdmin, lessons: state.lessons };
};

export default connect(
  mapStateToProps,
  { deleteLesson, fetchLessons }
)(LessonDeleteScreen);
