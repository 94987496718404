import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { editLesson, fetchLessons } from '../../actions';
import LessonFrom from './LessonForm';

class LessonEditScreen extends Component {
  componentDidMount() {
    const { courseId } = this.props.match.params;
    this.props.fetchLessons(courseId);
  }

  onSubmit = (courseId, lessonId, files, data) => {
    this.props.editLesson(courseId, lessonId, files, data);
  };

  render() {
    const { lessonId, courseId } = this.props.match.params;
    if (!this.props.lessons[lessonId]) {
      return <div>loading</div>;
    }
    const currentLesson = this.props.lessons[lessonId];
    const typeInt = currentLesson.type === 'image' ? 1 : 2;
    const initialState = {
      title: currentLesson.title,
      description: currentLesson.description,
      type: typeInt,
      files: null,
      order: currentLesson.order,
      previewimage: currentLesson.urls[0],
      fileNames: currentLesson.fileNames,
      caption: currentLesson.caption,
    };

    return (
      <Fragment>
        <LessonFrom
          initialState={initialState}
          onSubmit={this.onSubmit}
          courseId={courseId}
          lessonId={currentLesson.lessonId}
          order={lessonId}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return { lessons: state.lessons, isAdmin: state.auth.isAdmin };
};

export default connect(
  mapStateToProps,
  { editLesson, fetchLessons }
)(LessonEditScreen);
