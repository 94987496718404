import { db, storage } from '../config/firebase';

export const deleteLesson = (courseId, lessonId, fileNames) => async dispatch => {
  for (const fileName of fileNames) {
    storage
      .ref(`courses/lessons/${courseId}`)
      .child(fileName)
      .delete()
      .then(() => console.log(`delete file ${fileName}`));
  }
  db.collection('courses')
    .doc(courseId)
    .collection('lessons')
    .doc(lessonId)
    .delete()
    .then(() => {
      console.log(`delete lessonId ${lessonId}`);
    })
    .catch(error => {
      console.log(`Error removing document: ${error}`);
    });
};

export const deleteCourse = (courseId, imageNames) => async dispatch => {
  for (const imageName of imageNames) {
    storage
      .ref('courses/courseImages')
      .child(imageName)
      .delete()
      .then(() => console.log(`delete file ${imageName}`));
  }
  db.collection('courses')
    .doc(courseId)
    .delete()
    .then(() => {
      console.log(`delete courseId ${courseId}`);
    })
    .catch(error => {
      console.log(`Error removing document: ${error}`);
    });
};
