import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/functions';

const Config = () => {
  if (process.env.NODE_ENV === 'development') {
    return {
      firebase: {
        apiKey: process.env.REACT_APP_FB_DEV_API_KEY,
        authDomain: process.env.REACT_APP_FB_DEV_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FB_DEV_DATABASE_URL,
        projectId: process.env.REACT_APP_FB_DEV_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FB_DEV_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FB_DEV_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FB_DEV_APP_ID,
      },
    };
  } else if (process.env.NODE_ENV === 'test') {
    return {
      firebase: {
        apiKey: process.env.REACT_APP_FB_TEST_API_KEY,
        authDomain: process.env.REACT_APP_FB_TEST_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FB_TEST_DATABASE_URL,
        projectId: process.env.REACT_APP_FB_TEST_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FB_TEST_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FB_TEST_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FB_TEST_APP_ID,
      },
    };
  } else if (process.env.NODE_ENV === 'production') {
    return {
      firebase: {
        apiKey: process.env.REACT_APP_FB_PROD_API_KEY,
        authDomain: process.env.REACT_APP_FB_PROD_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FB_PROD_DATABASE_URL,
        projectId: process.env.REACT_APP_FB_PROD_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FB_PROD_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FB_PROD_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FB_PROD_APP_ID,
      },
    };
  }
  return {};
};

// Initialize Firebase
firebase.initializeApp(Config().firebase);

export default firebase;
export const db = firebase.firestore();
export const storage = firebase.storage();
