import React, { useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import './css/style.css';
import slide1 from './images/slide1.png';
import slide2 from './images/slide2.png';

import LoginForm from '../../components/LoginForm';
import Footer from '../../components/Footer';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    textAlign: 'center',
  },
}));

function LandingPageScreen(props) {
  document.title = 'CGCAMP | 初心者から3DCGを習得できるサービスCGCAMP';
  const classes = useStyles();
  const [signUpOpen, setSignUpOpen] = useState(false);

  const handleClickSignUpOpen = () => {
    setSignUpOpen(true);
  };

  const handleClose = () => {
    setSignUpOpen(false);
  };

  const renderSignUp = () => (
    <Dialog open={signUpOpen} onClose={handleClose} fullWidth maxWidth={'sm'}>
      <DialogTitle className={classes.dialogTitle}>新規登録</DialogTitle>
      <DialogContent>
        <LoginForm formText={'新規登録'} />
      </DialogContent>
    </Dialog>
  );

  if (props.isLoggedIn) {
    return <Redirect to={'/courses'} />;
  } else {
    return (
      <Fragment>
        <section className="hero" id="hero">
          <h1>3Dでものづくりを</h1>
          <p>CGCAMPはオンラインで3DCGが学べるサービスです。</p>
          <button onClick={handleClickSignUpOpen}>無料会員登録</button>
        </section>

        <section className="about" id="about">
          <h2 className="section__title">表現は2Dから3Dへ</h2>
          <p className="section__title--discription">
            画像と動画を使ったレッスンで「本質」を身に着けながら学習できます。
          </p>
          <div className="about__container">
            <div className="about__image">
              <img src={slide1} alt="coffee cup" />
            </div>
            <div className="about__text">
              <h3>3DCG初心者でも学習できます</h3>
              <p>
                3DCGを全く知らなくても大丈夫。基本的な概念から解説しているので、基礎から体系的に学習できます。
                演習を通した実践によって、本質的な実力を身に着けることができます。
              </p>
            </div>
          </div>
          <div className="about__container-reverse">
            <div className="about__image">
              <img src={slide2} alt="akm" />
            </div>
            <div className="about__text">
              <h3>高額なソフトなしで学習できる</h3>
              <p>
                CGCAMPの教材は無料のソフトを使用しています。
                高額なソフトが必要なく、パソコンだけで学習することができ、安価に本格的な作品を作ることができます。
              </p>
            </div>
          </div>
        </section>

        <section className="point" id="point">
          <h2 className="section__title">想像を形にする</h2>
          {/* <p className="section__title--discription">LEARN TO 3DCG, LEARN TO BE CREATIVE.</p> */}
          <p>コンピューターの進化で、誰でも自分の表現をできる時代がやってきました。</p>
          <br />
          <p>「初心者」でも「本質」を理解して、自らの想像を形にできるようになる。</p>
          <br />
          <p>このような思いをCGCAMPにこめています。</p>
        </section>
        <section className="cta" id="cta">
          <h2 className="section__title">さっそく始めよう</h2>
          <p className="section__title--discription">自分だけのオリジナルモデルを作ろう</p>
          <div>
            <button onClick={handleClickSignUpOpen}>無料会員登録</button>
            {renderSignUp()}
          </div>
        </section>
        <Footer />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return { isLoggedIn: state.auth.isLoggedIn };
};

export default connect(mapStateToProps)(LandingPageScreen);
