import React from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import CheckoutForm from '../components/CheckoutForm';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    width: '30em',
    margin: 'auto',
    marginTop: '8em',
    height: '100px',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
}));

const SubscriptionsScreen = () => {
  const classes = useStyles();
  return (
    <StripeProvider apiKey="pk_test_3OfJnt9xcL5w03mCctnIgJIh00SIrbyVms">
      <div className={classes.container}>
        <Elements>
          <CheckoutForm />
        </Elements>
      </div>
    </StripeProvider>
  );
};

export default SubscriptionsScreen;
