import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { createLesson } from '../../actions';
import Container from '@material-ui/core/Container';

import { connect } from 'react-redux';

import 'react-mde/lib/styles/css/react-mde-all.css';
import NotFound from '../../components/NotFound';
import csv from 'csv-parser';
const stream = csv();

class LessonCreateBatchfromCSV extends Component {
  state = {
    csv: [],
    files: null,
  };
  onSubmit = () => {
    const { courseId } = this.props.match.params;
    for (const data of this.state.csv) {
      // Rowオブジェクトなのでオブジェクトにする
      const dataObject = {
        title: data.title,
        description: data.description,
        type: data.type,
        order: data.order,
        caption: data.caption,
      };

      for (const file of this.state.files) {
        const fileTitle = file.name.split('.')[0];
        if (data.title === fileTitle) {
          // csvのタイトルとファイル名が同じならアップロードする
          const files = [file];
          this.props.createLesson(courseId, '', files, dataObject);
          console.log('create Lesson ' + data.title);
        }
      }
    }
  };

  handleChangeCSV = event => {
    // csvをヘッダーと値のオブジェクトの配列として保存する
    event.preventDefault();
    const reader = new FileReader();
    reader.onload = e => {
      stream.write(e.target.result);
    };
    if (event.target.files[0]) {
      const file = event.target.files[0];
      reader.readAsText(file);
    }
    let csv = [];
    stream.on('data', data => {
      csv.push(data);
    });
    this.setState({ csv: csv });
  };

  handleChangeFiles = event => {
    // フォルダの中にあるファイルリストのファイルオブジェクト
    this.setState({ files: event.target.files });
  };

  renderCSVUpload = () => {
    return (
      <div>
        <input style={{ display: 'none' }} accept={'*'} id="CSV" type="file" multiple onChange={this.handleChangeCSV} />
        <label htmlFor="CSV">
          <Button variant="outlined" component="span">
            CSVを選択
          </Button>
        </label>
      </div>
    );
  };

  renderFileUpload = () => {
    return (
      <div>
        <input
          style={{ display: 'none' }}
          accept={'*'}
          id="files"
          type="file"
          onChange={this.handleChangeFiles}
          webkitdirectory="true"
        />
        <label htmlFor="files">
          <Button variant="outlined" component="span">
            フォルダ選択
          </Button>
        </label>
      </div>
    );
  };

  render() {
    if (!this.props.isAdmin) {
      return <NotFound />;
    }
    return (
      <Container maxWidth="lg">
        {this.renderCSVUpload()}
        {this.renderFileUpload()}
        <div style={{ textAlign: 'right' }}>
          <Button onClick={this.onSubmit} variant="contained" color="primary">
            create
          </Button>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return { isAdmin: state.auth.isAdmin };
};

export default connect(
  mapStateToProps,
  { createLesson }
)(LessonCreateBatchfromCSV);
