import React, { Component, Fragment } from 'react';
import { editCourse, fetchCourses } from '../../actions';
import CorseForm from './CourseForm';
import { connect } from 'react-redux';

export class CourseEditScreen extends Component {
  componentDidMount() {
    const { courseId } = this.props.match.params;
    this.props.fetchCourses(courseId);
  }

  onSubmit = (courseId, eyecatchImage, productImage, data) => {
    this.props.editCourse(courseId, eyecatchImage, productImage, data);
  };

  render() {
    const { courseId } = this.props.match.params;
    if (!this.props.courses[courseId]) {
      return <div>loading</div>;
    }
    const currentCourse = this.props.courses[courseId];
    const initialState = {
      title: currentCourse.title,
      description: currentCourse.description,
      difficulty: currentCourse.difficulty,
      time_required: currentCourse.time_required,
      image: null,
      // databaseから初期地を設定しようとしたが、selectの初期値で固定されてしまうのでコメントアウト
      // prepCourses: currentCourse.prepCourses.map(course => course.courseId),
      prepCourses: [],
      softs: currentCourse.softs.join(','),
      image_src: currentCourse.eyecatchImageUrl,
      isPublished: currentCourse.isPublished,
      isFree: currentCourse.isFree,
      imageNames: currentCourse.imageNames,
      productImageUrl: currentCourse.productImageUrl,
    };
    return (
      <Fragment>
        <CorseForm initialState={initialState} onSubmit={this.onSubmit} courseId={courseId} />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return { courses: state.courses, isAdmin: state.auth.isAdmin };
};

export default connect(
  mapStateToProps,
  { editCourse, fetchCourses }
)(CourseEditScreen);
