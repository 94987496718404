import firebase, { db } from '../config/firebase';

import {
  EMAIL_CHANGED,
  PASSWORD_CHANGED,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGIN_USER,
  USER_EMAIL_CHANGED,
  USER_DISPLAYNAME_CHANGED,
  ADMIN_USER_SUCCESS,
  FETCH_USER,
  ADMIN_USER_FAIL,
  SIGN_UP_USER,
} from './types';

////////////////////emailとpasswordでサインアップ、ログイン用/////////////////////////////
export const emailChanged = text => {
  return {
    type: EMAIL_CHANGED,
    payload: text,
  };
};

export const passwordChanged = text => {
  return {
    type: PASSWORD_CHANGED,
    payload: text,
  };
};

export const loginUser = ({ email, password }) => async dispatch => {
  // emailとpasswordでログインする
  dispatch({
    type: LOGIN_USER,
  });

  firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(() => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(user => {
          dispatch({
            type: LOGIN_USER_SUCCESS,
            payload: user,
          });
        })
        .catch(error => {
          console.log(error);
          dispatch({
            type: LOGIN_USER_FAIL,
            payload: error.code,
          });
        });
    });
};

export const signUpUser = ({ email, password }) => async dispatch => {
  // emailとpasswordでログインする
  dispatch({
    type: SIGN_UP_USER,
  });

  firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then(user => {
      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: user,
      });
    })
    .catch(error => {
      console.log(error);
      dispatch({
        type: LOGIN_USER_FAIL,
        payload: error.code,
      });
    });
};

////////////////////ログイン処理/////////////////////////////

export const loggined = () => async dispatch => {
  const user = firebase.auth().currentUser;
  // データベースにユーザーのデータがなかったら初期値を登録
  // あったらデータベースのデータをstoreに登録
  const initialUserData = {
    uid: user.uid,
    image: user.photoURL ? user.photoURL : '',
    displayName: user.displayName ? user.displayName : '',
    email: user.email ? user.email : '',
    providerId: user.providerData[0].providerId ? user.providerData[0].providerId : '',
    mailVerified: user.emailVerified,
    isAnonymous: user.isAnonymous,
    stripeId: '',
    subscriptionId: '',
    status: '',
    itemId: '',
  };
  const ref = db.collection('users').doc(user.uid);
  const doc = await ref.get();
  if (doc.exists) {
    // データベースのユーザーデータを使う
    dispatch({
      type: LOGIN_USER_SUCCESS,
      payload: doc.data(),
    });
  } else {
    // データベースに初期値をセット
    ref.set(initialUserData);
    dispatch({
      type: LOGIN_USER_SUCCESS,
      payload: initialUserData,
    });
  }
};

export const logouted = () => {
  return {
    type: LOGIN_USER_FAIL,
    payload: null,
  };
};

////////////////////Admin処理/////////////////////////////

export const admin = () => {
  return {
    type: ADMIN_USER_SUCCESS,
  };
};

export const notAdmin = () => {
  return {
    type: ADMIN_USER_FAIL,
  };
};

///////////////////user情報の取得//////////////////////////

export const fetchUser = () => async dispatch => {
  const user = firebase.auth().currentUser;
  const ref = db.collection('users').doc(user.uid);
  const doc = await ref.get();
  if (doc.exists) {
    dispatch({
      type: FETCH_USER,
      payload: doc.data(),
    });
  }
};

///////////////////user情報の変更//////////////////////////

export const userEmailChanged = text => {
  return {
    type: USER_EMAIL_CHANGED,
    payload: text,
  };
};

export const userDisplayNameChanged = text => {
  return {
    type: USER_DISPLAYNAME_CHANGED,
    payload: text,
  };
};
