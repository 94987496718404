import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Link } from 'react-router-dom';

const DashboardCourse = props => {
  const progress = Math.floor((props.course.progress / props.course.allLessonsList) * 100);
  const nextLesson = parseInt(props.course.lessonId);
  let completeColor;
  if (props.course.complete) {
    completeColor = 'secondary';
  }

  const renderButton = () => {
    if (props.course.complete) {
      return (
        <Link to={`/courses/${props.course.courseId}/lessons/1`} style={{ textDecoration: 'none' }}>
          <Button style={{ marginTop: 20 }} variant="contained" color="primary">
            復習する
          </Button>
        </Link>
      );
    } else {
      return (
        <Link to={`/courses/${props.course.courseId}/lessons/${nextLesson}`} style={{ textDecoration: 'none' }}>
          <Button style={{ marginTop: 20 }} variant="contained" color="primary">
            続きから始める
          </Button>
        </Link>
      );
    }
  };
  return (
    <Fragment key={props.course.courseId}>
      <Box boxShadow={2} color="black" bgcolor="background.paper" p={4} style={{ marginTop: 20 }}>
        <Typography gutterBottom variant="h6" component="h2" style={{ marginBottom: 10 }}>
          {props.course.title}
        </Typography>
        <Typography gutterBottom variant="caption" component="h2">
          {props.course.progress} / {props.course.allLessonsList}レッスン完了
        </Typography>
        <LinearProgress color={completeColor} variant="buffer" value={progress} valueBuffer={100} />
        {renderButton()}
      </Box>
    </Fragment>
  );
};

export default DashboardCourse;
