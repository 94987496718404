import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Lesson from '../components/Lesson';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { fetchLessons } from '../actions';
import Container from '@material-ui/core/Container';
import _ from 'lodash';

const styles = theme => ({
  container: {
    margin: 100,
    [theme.breakpoints.down('md')]: {
      marginLeft: 100,
      marginRight: 100,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 50,
      marginRight: 50,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 10,
      marginRight: 10,
    },
  },
});

class LessonListScreen extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef(); // Create a ref object
  }

  componentDidMount() {
    document.title = 'レッスン一覧 | 初心者から3DCGを習得できるサービスCGCAMP';
    const { courseId } = this.props.match.params;
    this.props.fetchLessons(courseId);
    if (this.myRef.current) {
      this.myRef.current.scrollIntoView();
    }
  }

  renderList = () => {
    const { courseId } = this.props.match.params;
    const lessons = this.props.lessons;
    this.lessonsList = _.toArray(lessons);
    const currentLessonId = this.props.location.lessonId;
    return this.lessonsList.map(currentLesson => {
      if (currentLesson.order === currentLessonId) {
        return (
          <div key={currentLesson.title} ref={this.myRef}>
            <Lesson lesson={currentLesson} courseId={courseId} order={currentLesson.order} current={true} />
          </div>
        );
      }
      return (
        <div key={currentLesson.title}>
          <Lesson lesson={currentLesson} courseId={courseId} order={currentLesson.order} current={false} />
        </div>
      );
    });
  };

  renderCreateLesson = () => {
    const { courseId } = this.props.match.params;
    const lessons = this.props.lessons;
    this.lessonsList = _.toArray(lessons);
    if (this.props.isAdmin) {
      return (
        <Link
          to={{ pathname: `/courses/${courseId}/lessons/new`, state: { order: this.lessonsList.length + 1 } }}
          style={{ textDecoration: 'none' }}
        >
          新しくlessonを作成する
        </Link>
      );
    }
  };

  render() {
    return (
      <Container>
        <div className={this.props.classes.container}>
          {this.props.lessons ? (
            <Fragment>
              {this.renderCreateLesson()}
              {this.renderList()}
            </Fragment>
          ) : (
            'No courses found'
          )}
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return { lessons: state.lessons, isAdmin: state.auth.isAdmin };
};

const StyledLessonListScreen = withStyles(styles)(LessonListScreen);

export default connect(
  mapStateToProps,
  { fetchLessons }
)(StyledLessonListScreen);
