import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { deleteCourse, fetchCourse } from '../../actions';
import NotFound from '../../components/NotFound';

class CourseDeleteScreen extends Component {
  componentDidMount() {
    const { courseId } = this.props.match.params;
    this.props.fetchCourse(courseId);
  }
  onSubmit = () => {
    const { courseId } = this.props.match.params;
    const course = this.props.courses[courseId];

    this.props.deleteCourse(courseId, course.imageNames);
  };
  render() {
    if (!this.props.isAdmin) {
      return <NotFound />;
    } else {
      return (
        <div style={{ textAlign: 'center' }}>
          <Button variant="contained" color="primary" onClick={this.onSubmit}>
            Delete
          </Button>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return { isAdmin: state.auth.isAdmin, courses: state.courses };
};

export default connect(
  mapStateToProps,
  { deleteCourse, fetchCourse }
)(CourseDeleteScreen);
