import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { fetchRoutemap } from '../actions';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Rating from '@material-ui/lab/Rating';
import Lens from '@material-ui/icons/Lens';
import { withStyles } from '@material-ui/core/styles';

const StyledRating = withStyles({
  iconFilled: {
    color: '#2286c3',
  },
  iconHover: {
    color: '#ff3d47',
  },
})(Rating);

class RoutemapDetailScreen extends Component {
  componentDidMount() {
    const { routemapId } = this.props.match.params;
    this.props.fetchRoutemap(routemapId);
  }

  renderList = routemapCourses => {
    if (!routemapCourses.length) {
      return;
    }
    return routemapCourses.map(currentLesson => {
      const { title, courseId } = currentLesson;
      return (
        <Fragment key={courseId}>
          <Link to={{ pathname: `/courses/${courseId}` }} style={{ textDecoration: 'none' }}>
            <Box
              boxShadow={2}
              color="black"
              bgcolor="background.paper"
              m={7}
              p={4}
              style={{ margin: 'auto', marginTop: 50, width: '100%', height: '10em' }}
            >
              <Typography variant="body1" gutterBottom style={{ marginBottom: 30 }}>
                {title}
              </Typography>
            </Box>
          </Link>
        </Fragment>
      );
    });
  };

  renderContent = () => {
    const { routemapId } = this.props.match.params;
    if (Object.keys(this.props.routemaps).length) {
      const {
        title,
        description,
        subtitle,
        time_required,
        imageUrl,
        difficulty,
        routemapCourses,
      } = this.props.routemaps[routemapId];
      return (
        <Grid container>
          <Grid item xs={12} sm={8} style={{ padding: 50, paddingLeft: 100, marginTop: 80 }}>
            <Box
              bgcolor="primary.dark"
              color="primary.contrastText"
              style={{ width: 55, textAlign: 'center', borderRadius: 5 }}
            >
              <Typography variant="subtitle1" gutterBottom>
                Free
              </Typography>
            </Box>
            <Typography variant="h4" component="h2" gutterBottom style={{ marginBottom: 30 }}>
              {title}
            </Typography>
            <Typography variant="subtitle1" gutterBottom style={{ marginBottom: 30 }}>
              {subtitle}
            </Typography>

            <Box display="flex" flexDirection="row">
              <Box m={2}>
                <Typography variant="caption" gutterBottom>
                  修了時間
                </Typography>
                <Typography variant="body1" gutterBottom style={{ marginBottom: 30 }}>
                  {time_required}時間
                </Typography>
              </Box>
              <Box m={2}>
                <Typography variant="caption" gutterBottom>
                  難易度
                  <StyledRating
                    readOnly
                    size="small"
                    name="customized-color"
                    value={difficulty}
                    icon={<Lens fontSize="inherit" />}
                  />
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} style={{ padding: 50 }}>
            <div style={{ backgroundImage: `url(${imageUrl})` }}></div>
          </Grid>

          <Grid item xs={12} sm={6} style={{ padding: 50 }}>
            <Typography variant="h5" gutterBottom style={{ marginBottom: 20 }}>
              このコースについて
            </Typography>
            <Typography variant="body1" gutterBottom style={{ marginBottom: 30 }}>
              {description}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} style={{ padding: 50 }}>
            <Typography variant="h5" gutterBottom>
              ロードマップ
            </Typography>
            {this.renderList(routemapCourses)}
          </Grid>
        </Grid>
      );
    } else {
      return <Fragment>Loading</Fragment>;
    }
  };
  render() {
    return <div>{this.renderContent()}</div>;
  }
}

const mapStateToProps = state => {
  return { routemaps: state.routemaps };
};

export default connect(
  mapStateToProps,
  { fetchRoutemap }
)(RoutemapDetailScreen);
