import React, { Component, Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import ReactMde from 'react-mde';
import 'react-mde/lib/styles/css/react-mde-all.css';
import ReactMarkdown from 'react-markdown';
import NotFound from '../../components/NotFound';

class LessonForm extends Component {
  state = this.props.initialState;

  onSubmit = () => {
    const { title, description, type, files, order, fileNames, caption } = this.state;
    const typeString = type === 1 ? 'image' : 'movie';
    const stringOrder = String(order);
    this.props.onSubmit(this.props.courseId, this.props.lessonId, files, {
      title,
      description,
      order: stringOrder,
      type: typeString,
      fileNames,
      caption,
    });
    this.setState({
      title: '',
      description: '',
      type: 1,
      files: null,
      caption: '',
    });
  };

  handleChangeFile = event => {
    event.preventDefault();
    this.setState({ files: event.target.files });
    const createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL;
    const previewimage = createObjectURL(event.target.files[0]);
    this.setState({ previewimage });
  };

  renderContent = () => {
    return (
      <Fragment>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
          <div style={{ width: '50%', marginRight: 10 }}>
            <ReactMde value={this.state.description} onChange={text => this.setState({ description: text })} />
          </div>
          <div style={{ width: '50%' }}>
            Preview
            <Typography gutterBottom variant="body1" component="h2">
              <ReactMarkdown source={this.state.description} escapeHtml={false} />
            </Typography>
          </div>
        </div>
        <div>
          <input
            style={{ display: 'none' }}
            accept={'*'}
            id="file"
            type="file"
            multiple
            onChange={this.handleChangeFile}
          />
          <label htmlFor="file">
            <Button variant="outlined" component="span">
              ファイルを選択
            </Button>
          </label>
        </div>
        <div style={{ textAlign: 'center' }}>
          <img alt={this.state.title} src={this.state.previewimage} width={500} />
        </div>
      </Fragment>
    );
  };

  orderInput = text => {
    if (isNaN(parseInt(text.target.value))) {
      this.setState({ order: '' });
    } else {
      this.setState({ order: parseInt(text.target.value) });
    }
  };

  render() {
    if (!this.props.isAdmin) {
      return <NotFound />;
    }
    return (
      <Container maxWidth="lg">
        <div>
          <TextField
            id="standard-title"
            label="title"
            value={this.state.title}
            onChange={text => this.setState({ title: text.target.value })}
            margin="normal"
          />
        </div>

        <div>
          <InputLabel htmlFor="type-simple">type</InputLabel>
          <Select value={this.state.type} onChange={text => this.setState({ type: text.target.value })}>
            <MenuItem value={1}>image</MenuItem>
            <MenuItem value={2}>movie</MenuItem>
          </Select>
        </div>

        <div>
          <TextField label="order" value={this.state.order} onChange={this.orderInput} margin="normal" />
        </div>

        {this.renderContent()}

        <div>
          <TextField
            id="standard-caption"
            label="caption"
            value={this.state.caption}
            onChange={text => this.setState({ caption: text.target.value })}
            margin="normal"
          />
        </div>

        <div style={{ textAlign: 'right' }}>
          <Button onClick={this.onSubmit} variant="contained" color="primary">
            <Link to={`/courses/${this.props.courseId}/lessons/${this.props.order}`} style={{ color: 'white' }}>
              create
            </Link>
          </Button>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return { isAdmin: state.auth.isAdmin };
};

export default connect(mapStateToProps)(LessonForm);
