import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  type: 'light',
  palette: {
    primary: {
      light: '#69e2ff', // 基本の色よりも明るい色
      main: '#00b0ff', // 基本の色
      dark: '#0081cb', // 基本の色よりも暗い色
      contrastText: '#fff', // テキストの色
    },
    secondary: {
      light: '#fff64f',
      main: '#ffc400',
      dark: '#c79400',
      contrastText: '#000',
    },
  },
});

// 色参考
// #f7c06a
