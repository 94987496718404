import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '70%',
    textAlign: 'center',
  },
  div: { marginTop: 20 },
});

function NotFound() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <h1>Page Not Found</h1>
        <div className={classes.div}>指定しているページは見つかりませんでした。</div>
        <Link to={'/courses'}>
          <div className={classes.div}>コース一覧にもどる</div>
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
