import { db, storage } from '../config/firebase';
import uniqid from 'uniqid';
import { CREATE_COURSE, CREATE_ROUTEMAP } from './types';

const saveCourseDatabase = async data => {
  const dateForISO = new Date();
  data.create_at = dateForISO.toISOString();
  data.update_at = '';
  await db.collection('courses').add(data);
  console.log('Save Course Database');
};

export const createCourse = (courseId, eyecatchImage, productImage, data) => async dispatch => {
  data.imageNames = [];
  data.eyecatchImageUrl = '';
  data.productImageUrl = '';
  let count = 1;
  if (productImage) {
    count += 1;
    const extention = productImage.name.split('.').pop();
    const uniqFilename = uniqid() + '.' + extention;
    const uploadTask = storage
      .ref('courses/courseImages')
      .child(uniqFilename)
      .put(productImage);
    uploadTask.on(
      'state_changed',
      snapshot => {},
      error => {
        console.log(`error with upload${error}`);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          data.productImageUrl = downloadURL;
          data.imageNames.push(uniqFilename);
          if (data.imageNames.length === count) {
            saveCourseDatabase(data);
          }
        });
      }
    );
  }
  const extention = eyecatchImage.name.split('.').pop();
  const uniqFilename = uniqid() + '.' + extention;
  const uploadTask = storage
    .ref('courses/courseImages')
    .child(uniqFilename)
    .put(eyecatchImage);
  uploadTask.on(
    'state_changed',
    snapshot => {},
    error => {
      console.log(`error with upload${error}`);
    },
    () => {
      uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
        data.eyecatchImageUrl = downloadURL;
        data.imageNames.push(uniqFilename);
        if (data.imageNames.length === count) {
          saveCourseDatabase(data);
        }
      });
    }
  );
  dispatch({
    type: CREATE_COURSE,
  });
};

const saveLessonDatabase = async (courseId, data) => {
  const dateForISO = new Date();
  data.create_at = dateForISO.toISOString();
  data.update_at = '';
  await db
    .collection('courses')
    .doc(courseId)
    .collection('lessons')
    .add(data);
  console.log('Save Lesson Databse');
};

export const createLesson = (courseId, lessonId, files, data) => async dispatch => {
  if (files) {
    // ファイルアップロード urlsに画像と動画のパス、filenamesにファイルの名前、exercisesFilesに演習ファイルのパス
    data.urls = [];
    data.fileNames = [];
    data.exercisesFiles = [];
    const fileLength = files.length;
    for (const file of files) {
      const extention = file.name.split('.').pop();
      const uniqFilename = uniqid() + '.' + extention;
      if (extention === 'blend') {
        // 演習ファイルの場合ファイル名のまま保存してexercisesfilesに加える
        const uploadTask = storage
          .ref(`courses/lessons/${courseId}`)
          .child(file.name)
          .put(file);
        uploadTask.on(
          'state_changed',
          snapshot => {},
          error => {
            console.log(`error with upload${error}`);
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
              data.exercisesFiles.push(downloadURL);
              data.fileNames.push(file.name);
              if (data.urls.length + data.exercisesFiles.length === fileLength) {
                saveLessonDatabase(courseId, data);
              }
            });
          }
        );
      } else {
        const uploadTask = storage
          .ref(`courses/lessons/${courseId}`)
          .child(uniqFilename)
          .put(file);
        uploadTask.on(
          'state_changed',
          snapshot => {},
          error => {
            console.log(`error with upload${error}`);
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
              data.urls.push(downloadURL);
              data.fileNames.push(uniqFilename);
              if (data.urls.length + data.exercisesFiles.length === fileLength) {
                saveLessonDatabase(courseId, data);
              }
            });
          }
        );
      }
    }
  } else {
    saveLessonDatabase(courseId, data);
  }
};

export const createRoutemap = data => async dispatch => {
  const dateForISO = new Date();
  data.create_at = dateForISO.toISOString();
  await db.collection('routemaps').add(data);

  dispatch({
    type: CREATE_ROUTEMAP,
  });
};
