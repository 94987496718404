import React from 'react';
import Footer from '../components/Footer';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  container: {
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    maxWidth: '740px !important',
    padding: '40px 20px',
  },
  title: {
    color: 'rgb(68, 68, 68)',
    fontWeight: '900',
    fontSize: '24px',
    textAlign: 'center',
    marginBottom: '36px',
  },
  subtitle: {
    color: 'rgb(68, 68, 68)',
    fontWeight: '900',
    fontSize: '16px',
    textAlign: 'left',
    marginBottom: '12px',
    borderBottom: '1px solid rgb(238, 238, 238)',
  },
  description: {
    fontSize: '14px',
    lineHeight: '30px',
    marginBottom: '40px',
    color: 'rgb(68, 68, 68)',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100% + 300px)',
  },
});

function TermOfServiceScreen() {
  window.scrollTo(0, 0);

  document.title = '利用規約 | 初心者から3DCGを習得できるサービスCGCAMP';
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.title}>サービス利用規約</div>
        <div className={classes.description}>
          本利用規約（以下、本規約）には、本サービスの提供条件及び当サービスの運営チームと登録ユーザーの皆様との間の権利義務関係が定められています。本サービスの利用に際しては、本規約の全文をお読みいただいた上で、本規約に同意いただく必要があります。
        </div>
        <div className={classes.subtitle}>第1条（適用）</div>
        <div className={classes.description}>
          （1）本規約は、CGCAMP（以下、本サービス）の提供条件及び本サービスの利用に関する運営チームと登録ユーザーとの間の権利義務関係を定めることを目的とし、登録ユーザーと運営チームとの間の本サービスの利用にかかわる一切の関係に適用されます。
          <br />
          （2）本規約の内容とその他の本規約外における本サービスの説明等とが異なる場合は、本規約の規定が優先して適用されるものとします。
        </div>
        <div className={classes.subtitle}>第2条（定義）</div>
        <div className={classes.description}>
          本規約において使用する以下の用語は、各々以下に定める意味を有するものとします。
          <br />
          （1）「サービス利用規約」とは、本規約および運営チームと登録ユーザーの間で締結する、本サービスの利用規約を意味します。
          <br />
          （2）「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権（それらの権利を取得し、またそれらの権利につき登録等を出願する権利を含みます。）を意味します。
          <br />
          （3）「運営チーム」とは、CGCAMPの運営チームを意味します。
          <br />
          （4）「登録ユーザー」とは、第3条（登録）に基づいて本サービスの利用者としての登録がなされた個人または法人を意味します。
          <br />
          （5）「本サービス」とは、CGCAMPという名称のサービス（理由の如何を問わずサービスの名称または内容が変更された場合は、当該変更後のサービスを含みます。）を意味します。
        </div>
        <div className={classes.subtitle}>第3条（登録）</div>
        <div className={classes.description}>
          （1）本サービスの利用を希望するもの（以下「登録希望者」といいます。）は、本規約を尊守することに同意し、かつ運営チームの定める一定の情報（以下「登録事項」といいます。）を運営チームの定める方法で運営チームに提供することにより、運営チームに対し、本サービスの利用を申請することができます。
          <br />
          （2）運営チームは、登録申請者が以下の各号のいずれかの事由に該当する場合は、登録及び再登録を拒否することがあり、またその理由について一切開示義務を負いません。
          <br />
          ・運営チームに提供した登録事項の全部または一部につき虚偽、誤記又は記載漏れがあった場合
          <br />
          ・反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、または資金提供その他を通じて反社会勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等の何らかの交流もしくは関与を行っていると運営チームが判断した場合
          <br />
          ・登録希望者が過去運営チームとの契約に違反した者またはその関係者であると運営チームが判断した場合
          <br />
          ・第7条に定める措置を受けたことがある場合
          <br />
          ・その他、運営チームが登録を適当でないと判断した場合
        </div>
        <div className={classes.subtitle}>第4条（パスワード及びユーザーIDの管理）</div>
        <div className={classes.description}>
          （1）登録ユーザーは、自己の責任において、本サービスに関するパスワード及びユーザーIDを適切に管理及び保管するものとし、これを第三者に利用させ、または貸与、譲渡、名義変更、売買等をしてはならないものとします。
          <br />
          （2）パスワードまたはユーザーIDの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任は登録ユーザーが負うものとし、運営チームは一切の責任を負いません。
        </div>
        <div className={classes.subtitle}>第5条（禁止事項）</div>
        <div className={classes.description}>
          登録ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為または該当すると運営チームが判断する行為をしてはなりません。
          <br />
          （1）法令に違反する行為または犯罪行為に関連する行為
          <br />
          （2）運営チーム、本サービスのほかの利用者またはその他の第三者に対する詐欺または脅迫行為
          <br />
          （3）公序良俗に反する行為
          <br />
          （4）運営チーム、本サービスの他の利用者またはその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為
          <br />
          （5）本サービスのネットワークまたはシステム等に過度な負荷を掛ける行為
          <br />
          （6）本サービスの運営を妨害するおそれのある行為
          <br />
          （7）運営チームのネットワークまたはシステム等に不正にアクセスし、または不正なアクセスを試みる行為
          <br />
          （8）第三者に成りすます行為
          <br />
          （9）本サービスの他の利用者のIDまたはパスワードを利用する行為
          <br />
          （10）本サービスの他の利用者の情報の収集
          <br />
          （11）本サービスの他の利用者またはその他の第三者に不利益、損害、不快感を与える行為
          <br />
          （12）反社会的勢力への利益供与
          <br />
          （13）前各号の行為を直接または間接に惹起し、また容易にする行為
          <br />
          （14）その他、運営チームが不適切と判断する行為
        </div>
        <div className={classes.subtitle}>第6条（本サービスの停止等）</div>
        <div className={classes.description}>
          運営チームは、以下のいずれかの事由があると判断した場合、登録ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
          <br />
          （1）本サービスにかかるコンピュータシステムの保守点検または更新を行う場
          <br />
          （2）地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合
          <br />
          （3）コンピュータまたは通信回線等が事故により停止した場合
          <br />
          （4）その他、運営チームが本サービスの提供が困難と判断した場合
          <br />
          運営チームは、本サービスの提供の停止または中断により、登録ユーザーまたは第三者が被ったいかなる不利益または損害について、理由を問わず一切の責任を負わないものとします。
        </div>
        <div className={classes.subtitle}>第7条（登録抹消等）</div>
        <div className={classes.description}>
          運営チームは、登録ユーザーが、以下の各号のいずれかの事由に該当する場合は、事前に通知または催告することなく、当該登録ユーザーについて本サービスの利用を一時的に停止し、または登録ユーザーとしての登録を抹消、もしくはサービス利用契約を解除することができます。
          <br />
          （1）本規約のいずれかの条項に違反した場合
          <br />
          （2）登録事項に虚偽の事実があることが判明した場合
          <br />
          （3）その他、運営チームが本サービスの利用、登録ユーザーとしての登録、またはサービスの利用契約の継続を適切でないと判断した場合
          <br />
          （4）運営チームは、本条に基づき運営チームが行った行為により登録ユーザーに生じた損害について一切の責任を負いません。
        </div>
        <div className={classes.subtitle}>第8条（権利帰属）</div>
        <div className={classes.description}>
          本サービスに関する知的財産権は全て運営チームまたは運営チームにライセンスを許諾している者に帰属しており、本規約に基づく本サービスの利用許諾は、本サービスに関する運営チームまたは運営チームにライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。
          <br />
        </div>
        <div className={classes.subtitle}>第9条（免責事項）</div>
        <div className={classes.description}>
          運営チームの債務不履行責任は、運営チームの故意または重過失によらない場合には免責されるものとします。運営チームは、本サービスに関して、登録ユーザーと他の登録ユーザーまたは第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。
        </div>
        <div className={classes.subtitle}>第10条（サービス内容の変更等）</div>
        <div className={classes.description}>
          運営チームは、登録ユーザーに通知することなく、本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし、これによって登録ユーザーに生じた損害について一切の責任を負いません。
        </div>
        <div className={classes.subtitle}>第11条（利用規約の変更）</div>
        <div className={classes.description}>
          運営チームは、必要と判断した場合には、登録ユーザーに通知することなくいつでも本規約を変更することができるものとします。
        </div>
        <div className={classes.subtitle}>第12条（通知または連絡）</div>
        <div className={classes.description}>
          登録ユーザーと運営チームとの間の通知または連絡は、運営チームの定める方法によって行うものとします。
        </div>
        <div className={classes.subtitle}>第13条（利用者情報の扱い）</div>
        <div className={classes.description}>
          （1）運営チームによる登録ユーザーの利用者情報の取扱いについては、別途運営チームのプライバシーポリシーの定めによるものとし、登録ユーザーはこのプライバシーポリシーに従って運営チームが登録ユーザーの利用者情報を扱うことについて同意するものとします。
          <br />
          （2）運営チームは、登録ユーザーが運営チームに提供した情報、データ等を、個人を特定できない形での統計的な情報として、運営チームの裁量で、利用及び公開ができるものとし、登録ユーザーはこれに異議を唱えないものとします。
        </div>
        <div className={classes.subtitle}>第14条（サービス利用契約上の地位の譲渡等）</div>
        <div className={classes.description}>
          （1）登録ユーザーは、運営チームの書面による事前の承諾なく、サービス利用契約上の地位や本規約に基づく権利義務等を、第三者に対し譲渡、移転、担保設定、その他の処分をすることはできません。
          <br />
          （2）運営チームが本サービスにかかる事業を他社に譲渡した場合、運営チームは、その事業譲渡に伴い、サービス利用契約上の地位、本規約に基づく権利及び義務、登録ユーザーの登録事項とその他の顧客情報をその事業譲渡の譲受人に対して譲渡することができます。登録ユーザーは、このことについて本項で予め同意したものとします。
        </div>
        <div className={classes.subtitle}>第15条（準拠法・裁判管轄）</div>
        <div className={classes.description}>
          本規約の解釈にあたっては、日本法を準拠法とします。
          本サービスに関して紛争が生じた場合には、福岡地方裁判所を第一審の専属的合意管轄裁判所とします。
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TermOfServiceScreen;
