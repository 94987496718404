import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  footer: {
    fontSize: '14px',
    color: 'rgb(68, 68, 68)',
    width: '100%',
    background: 'rgb(255, 255, 255)',
    padding: '30px 0px',
    display: 'flex',
    minHeight: '20vh',
    marginTop: 'auto',
    flexDirection: 'column',
    borderTop: '1px solid rgba(0,0,0,.12)',
  },
  container: {
    width: '100%',
    paddingRight: '15px',
    paddingLeft: '15px',
    marginRight: 'auto',
    marginLeft: 'auto',
    maxWidth: '80%',
  },
  gridItem: {
    textAlign: 'left',
  },
  text: {
    marginBottom: 10,
  },
  link: {
    color: 'rgb(68, 68, 68)',
    textDecoration: 'none',
  },
});

function Footer() {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <Grid container justify="center" spacing={4}>
          <Grid item xs={12} sm={6} md={4} lg={3} className={classes.gridItem}>
            <div className={classes.text}>
              <Link to={'/terms-of-service'} className={classes.link}>
                利用規約
              </Link>
            </div>
            <div className={classes.text}>
              <Link to={'/privacy-policy'} className={classes.link}>
                プライバシーポリシー
              </Link>
            </div>
            <div className={classes.text}>
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://docs.google.com/forms/d/e/1FAIpQLSeclKz8o24nMiT53SXG_R_Sqpd2O_CuD2g9XuOoJiFabWbmUQ/viewform?usp=sf_link"
                className={classes.link}
              >
                お問い合わせ
              </a>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}></Grid>
          <Grid item xs={false} sm={6} md={4} lg={3}></Grid>
          <Grid item xs={false} sm={6} md={4} lg={3}></Grid>
        </Grid>
      </div>
      <div>&copy; 2019 CGCAMP</div>
    </footer>
  );
}

export default Footer;
