import React, { Component, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import Course from '../components/Course';
import { Link } from 'react-router-dom';
import { fetchCourses } from '../actions';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Footer from '../components/Footer';
import _ from 'lodash';
import { withStyles } from '@material-ui/styles';

import LoginForm from '../components/LoginForm';

const styles = {
  dialogTitle: {
    textAlign: 'center',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100% + 300px)',
  },
};

class CourseListScreen extends Component {
  state = {
    signUpOpen: false,
    formText: '新規登録',
  };

  componentDidMount() {
    this.props.fetchCourses();
    document.title = 'コース一覧 | 初心者から3DCGを習得できるサービスCGCAMP';
  }

  handleClose = () => {
    this.setState({ signUpOpen: false });
  };

  handleClickSignUpOpen = () => {
    this.setState({ signUpOpen: true });
  };

  onFormTextSignUp = () => {
    this.setState({ formText: '新規登録' });
  };

  onFormTextLogin = () => {
    this.setState({ formText: 'ログイン' });
  };

  renderSwitchLoginOrSignUp = () => {
    if (this.state.formText === 'ログイン') {
      return (
        <div style={{ textAlign: 'center', margin: 20 }}>
          アカウントをお持ちでないですか？
          <span onClick={this.onFormTextSignUp} style={{ color: 'rgb(0, 112, 210)', cursor: 'pointer' }}>
            新規登録
          </span>
        </div>
      );
    } else {
      return (
        <div style={{ textAlign: 'center', margin: 20 }}>
          すでにアカウントをお持ちですか？{' '}
          <span onClick={this.onFormTextLogin} style={{ color: 'rgb(0, 112, 210)', cursor: 'pointer' }}>
            ログイン
          </span>
        </div>
      );
    }
  };

  renderSignUp = () => {
    if (this.state.signUpOpen && this.props.isLoggedIn) {
      this.setState({ signUpOpen: false });
    }
    return (
      <Dialog open={this.state.signUpOpen} onClose={this.handleClose} fullWidth maxWidth={'sm'}>
        <DialogTitle className={this.props.classes.dialogTitle}>{this.state.formText}</DialogTitle>
        <DialogContent>
          <LoginForm formText={this.state.formText} />
          {this.renderSwitchLoginOrSignUp()}
        </DialogContent>
      </Dialog>
    );
  };

  renderList = () => {
    const courses = this.props.courses;
    this.coursesList = _.toArray(courses);
    // if (this.props.isLoggedIn) {
    if (true) {
      return this.coursesList.map(currentCourse => {
        if (currentCourse.isPublished) {
          //公開済みならリターン
          return (
            <Grid key={currentCourse.courseId} item xs={12} sm={6} md={4} lg={3}>
              <Link to={`/courses/${currentCourse.courseId}`} style={{ textDecoration: 'none' }}>
                <Course course={currentCourse} />
              </Link>
            </Grid>
          );
        } else if (this.props.isAdmin) {
          //非公開でもAdminならリターン
          return (
            <Grid key={currentCourse.courseId} item xs={12} sm={6} md={4} lg={3}>
              <Link to={`/courses/${currentCourse.courseId}`} style={{ textDecoration: 'none' }}>
                <Course course={currentCourse} />
              </Link>
            </Grid>
          );
        } else {
          return;
        }
      });
    } else {
      // ログインしてなかったらダイアログ表示
      return this.coursesList.map(currentCourse => {
        if (currentCourse.isPublished) {
          return (
            <Grid key={currentCourse.courseId} item xs={12} sm={6} md={4} lg={3}>
              <div onClick={this.handleClickSignUpOpen}>
                <Course course={currentCourse} />
              </div>
            </Grid>
          );
        }
      });
    }
  };

  renderCreateCourse = () => {
    if (this.props.isAdmin) {
      return (
        <Link to={`/courses/new`} style={{ textDecoration: 'none' }}>
          新しくコースを作成する
        </Link>
      );
    }
  };

  render() {
    return (
      <div className={this.props.classes.wrapper}>
        <Container style={{ marginBottom: 30 }}>
          {this.props.courses ? (
            <div>
              {this.renderSignUp()}
              <div style={{ textAlign: 'center', marginTop: 40 }}>
                <Typography gutterBottom variant="h4" component="h2">
                  学習コース一覧
                </Typography>
                <Typography gutterBottom variant="body1">
                  興味のあるコースを選択してみよう！
                </Typography>
                <Typography gutterBottom variant="body2">
                  初めて3DCGをさわる方は「3DCG入門」でざっくり専門用語を理解してみましょう！
                </Typography>
              </div>
              <Grid container justify="center" spacing={4} style={{ marginTop: 20 }}>
                {this.renderList()}
              </Grid>
              {this.renderCreateCourse()}
            </div>
          ) : (
            'No courses found'
          )}
        </Container>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { courses: state.courses, isAdmin: state.auth.isAdmin, isLoggedIn: state.auth.isLoggedIn };
};

const StyledCourseListScreen = withStyles(styles)(CourseListScreen);

export default connect(
  mapStateToProps,
  { fetchCourses }
)(StyledCourseListScreen);
