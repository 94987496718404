import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';

const useStyles = makeStyles({
  box: {
    '&:hover': {
      background: 'rgb(240, 244, 249)',
    },
  },
  current: {
    background: 'rgb(210, 225, 240)',
  },
});

const Lesson = props => {
  const classes = useStyles();
  const editLesson = () => {
    if (props.isAdmin) {
      return (
        <Link to={`/courses/${props.courseId}/lessons/edit/${props.order}`} style={{ textDecoration: 'none' }}>
          edit
        </Link>
      );
    }
  };
  return (
    <Fragment>
      {props.lesson ? (
        <Fragment>
          <Link to={`/courses/${props.courseId}/lessons/${props.order}`} style={{ textDecoration: 'none' }}>
            <Box
              className={props.current ? classes.current : classes.box}
              boxShadow={1}
              color="black"
              bgcolor="background.paper"
              m={0}
              p={2.5}
            >
              <Typography gutterBottom variant="subtitle1" component="h2">
                {props.lesson.order}、{props.lesson.title}
              </Typography>
            </Box>
          </Link>
          {editLesson()}
        </Fragment>
      ) : null}
    </Fragment>
  );
};

const mapStateToProps = state => {
  return { isAdmin: state.auth.isAdmin };
};

export default connect(mapStateToProps)(Lesson);
