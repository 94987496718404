import React, { Component } from 'react';
import { CardNumberElement, CardExpiryElement, CardCVCElement, injectStripe } from 'react-stripe-elements';
import './css/checkoutFormStyle.css';
import firebase from '../config/firebase';
import { fetchUser } from '../actions';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = { complete: true, errorMessage: '', loading: false };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(ev) {
    ev.preventDefault();
    let { token } = await this.props.stripe.createToken({ name: 'Name' });
    if (!token) {
      return;
    }
    // CloudFunction名を指定してたたく
    this.setState({ loading: true });
    firebase
      .functions()
      .httpsCallable('startSubscription')({ source: token.id })
      .then(() => {
        this.setState({ loading: false });
        this.setState({ errorMessage: '' });
        this.setState({ complete: true });
        this.props.fetchUser();
        console.log('start subscription');
      });
  }

  handleChange = ({ error }) => {
    if (error) {
      this.setState({ errorMessage: error.message });
    }
  };

  render() {
    if (this.state.loading) {
      return (
        <div style={{ textAlign: 'center' }}>
          <div>クレジットカード情報を処理しています</div>
          <CircularProgress style={{ marginTop: 100 }} />;
        </div>
      );
    } else if (this.state.complete) {
      return (
        <div style={{ textAlign: 'center' }}>
          <div className="CheckoutFormComplete">登録が完了しました。ありがとうございます！</div>
          <Link to={'/dashboard'} style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="primary">
              ダッシュボードに戻る
            </Button>
          </Link>
        </div>
      );
    }

    return (
      <div>
        <h3 className="CheckoutFormTitle">クレジットカード登録</h3>
        <div className="CheckoutFormComplete">{this.state.completeMessage}</div>
        <form onSubmit={this.handleSubmit}>
          <p>カード番号</p>
          <CardNumberElement onChange={this.handleChange} />
          <p>有効期限</p>
          <CardExpiryElement />
          <p>CVCコード</p>
          <CardCVCElement />
          <div className="error" role="alert">
            {this.state.errorMessage}
          </div>
          <button className="CheckoutFormButton">登録</button>
        </form>
      </div>
    );
  }
}

const CheckoutFormConnect = connect(
  null,
  { fetchUser }
)(CheckoutForm);

export default injectStripe(CheckoutFormConnect);
