import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { connect } from 'react-redux';
import { createRoutemap, fetchCourses } from '../../actions';
import _ from 'lodash';

export class RoutemapCreateScreen extends Component {
  state = {
    title: '',
    description: '',
    difficulty: 1,
    time_required: 1,
    routemapCourses: [],
    softs: '',
    subtitle: '',
  };

  componentDidMount() {
    this.props.fetchCourses();
  }

  handleChange = event => {
    this.setState({ routemapCourses: event.target.value });
  };

  handleChangeFile = event => {
    event.preventDefault();
    this.setState({ image: event.target.files[0] });
    const createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL;
    const image_src = createObjectURL(event.target.files[0]);
    this.setState({ image_src });
  };

  onButtonPress = () => {
    const { title, description, difficulty, subtitle, softs, time_required, routemapCourses } = this.state;
    const softsList = softs.split(',');
    const routemapCoursesList = routemapCourses.map(id => this.props.courses[id]);
    this.props.createRoutemap({
      title,
      description,
      difficulty,
      subtitle,
      time_required,
      softs: softsList,
      routemapCourses: routemapCoursesList,
    });
    this.setState({
      title: '',
      description: '',
      difficulty: 1,
      time_required: 1,
      routemapCourses: [],
      softs: [],
      subtitle: '',
    });
  };

  render() {
    const coursesList = _.toArray(this.props.courses);
    if (!coursesList.length) {
      return <div>Loading</div>;
    }

    return (
      <Container maxWidth="sm">
        <div>受講ルートを作成する</div>
        <div style={{ textAlign: 'center' }}>
          <TextField
            id="standard-title"
            label="title"
            value={this.state.title}
            onChange={text => this.setState({ title: text.target.value })}
            margin="normal"
          />
        </div>
        <div style={{ textAlign: 'center' }}>
          <TextField
            id="standard-subtitle"
            label="subtitle"
            value={this.state.subtitle}
            onChange={text => this.setState({ subtitle: text.target.value })}
            margin="normal"
          />
        </div>
        <div style={{ textAlign: 'center' }}>
          <TextField
            id="outlined-description-flexible"
            label="description"
            multiline
            rowsMax="20"
            value={this.state.description}
            onChange={text => this.setState({ description: text.target.value })}
            margin="normal"
            variant="outlined"
          />
        </div>
        <div style={{ textAlign: 'center' }}>
          <div>ソフト名をカンマ区切りで入力してください</div>
          <TextField
            id="outlined-softs-flexible"
            label="softs"
            multiline
            rowsMax="20"
            value={this.state.softs}
            onChange={text => this.setState({ softs: text.target.value })}
            margin="normal"
            variant="outlined"
          />
        </div>
        <div style={{ textAlign: 'center' }}>
          <InputLabel htmlFor="time_require-simple">time_required</InputLabel>
          <Select
            value={this.state.time_required}
            onChange={text => this.setState({ time_required: text.target.value })}
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
          </Select>
        </div>
        <div style={{ textAlign: 'center' }}>
          <InputLabel htmlFor="difficulty-simple">difficulty</InputLabel>
          <Select value={this.state.difficulty} onChange={text => this.setState({ difficulty: text.target.value })}>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
          </Select>
        </div>
        <div style={{ textAlign: 'center' }}>
          <InputLabel htmlFor="select-multiple">Courses</InputLabel>
          <Select
            multiple
            value={this.state.routemapCourses}
            onChange={this.handleChange}
            input={<Input id="select-multiple" />}
          >
            {coursesList.map(course => (
              <MenuItem key={course.courseId} value={course.courseId}>
                {course.title}
              </MenuItem>
            ))}
          </Select>
        </div>

        <div style={{ textAlign: 'center' }}>
          <Button onClick={this.onButtonPress} variant="contained" color="primary">
            create
          </Button>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return { courses: state.courses };
};

export default connect(
  mapStateToProps,
  { createRoutemap, fetchCourses }
)(RoutemapCreateScreen);
