import React, { Component, Fragment } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { loggined, logouted, admin, notAdmin } from './actions';
import { connect } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';

import firebase from './config/firebase';
import Auth from './Auth';

import LandingPage from './screens/landingPage/LandingPageScreen';
import LessonListScreen from './screens/LessonListScreen';
import LessonDetailScreen from './screens/LessonDetailScreen';
import CourseCreateScreen from './screens/Admin/CourseCreateScreen';
import CourseEditScreen from './screens/Admin/CourseEditScreen';
import CourseDeleteScreen from './screens/Admin/CourseDeleteScreen';
import LessonCreateScreen from './screens/Admin/LessonCreateScreen';
import LessonDeleteScreen from './screens/Admin/LessonDeleteScreen';
import LessonCreateBatchfromCSV from './screens/Admin/LessonCreateBatchfromCSV';
import LessonEditScreen from './screens/Admin/LessonEditScreen';
import CourseListScreen from './screens/CourseListScreen';
import CourseDetailScreen from './screens/CourseDetailScreen';
import RoutemapsListScreen from './screens/RoutemapsListScreen';
import DashboardScreen from './screens/DashboardScreen';
import SettingsScreen from './screens/SettingsScreen';
import SubscriptionsScreen from './screens/SubscriptionsScreen';
import RoutemapCreateScreen from './screens/Admin/RoutemapCreateScreen';
import RoutemapDetailScreen from './screens/RoutemapDetailScreen';
import NotFound from './components/NotFound';
import PrivacyPolicyScreen from './screens/PrivacyPolicyScreen';
import TermOfServiceScreen from './screens/TermOfServiceScreen';

import Snackbar from '@material-ui/core/Snackbar';
import NavBar from './components/NavBar';
import CircularIndeterminate from './components/CircularIndeterminate';
import ReactGA from 'react-ga';
import { theme } from './theme';
import history from './history';
import SnackbarContentWrapper from './components/SnackbarContentWrapper';

// Googleアナリティクスの設定
ReactGA.initialize('UA-146112242-1');
history.listen(({ pathname }) => {
  ReactGA.set({ page: pathname });
  ReactGA.pageview(pathname);
});

class App extends Component {
  state = {
    snackbarOpen: false,
    notification: '',
  };
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);

    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.props.loggined();
        console.log('loginしました');
        user.getIdTokenResult(true).then(idTokenResult => {
          if (idTokenResult.claims.admin) {
            // current user has admin
            this.props.admin();
          } else {
            this.props.notAdmin();
          }
        });
      } else {
        if (this.props.isLoggedIn) {
          this.setState({ snackbarOpen: true, notification: 'ログアウトしました' });
        }

        this.props.logouted();
        this.props.notAdmin();
      }
    });
  }

  handleClick = () => {
    this.setState({ snackbarOpen: true });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ snackbarOpen: false });
  };

  renderContent = () => {
    if (this.props.isLoggedIn === null || this.props.isAdmin === null) {
      return <CircularIndeterminate />;
    } else {
      return (
        <MuiThemeProvider theme={theme}>
          <Router history={history}>
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={this.state.snackbarOpen}
              autoHideDuration={3000}
              onClose={this.handleClose}
            >
              <SnackbarContentWrapper onClose={this.handleClose} variant="success" message={this.state.notification} />
            </Snackbar>
            <NavBar />
            <Switch>
              <Route path="/" exact component={LandingPage} />
              <Route path="/privacy-policy" exact component={PrivacyPolicyScreen} />
              <Route path="/terms-of-service" exact component={TermOfServiceScreen} />
              <Route path="/courses" exact component={CourseListScreen} />
              {/* ここから */}
              <Route path="/courses/new" exact component={CourseCreateScreen} />
              <Route path="/courses/:courseId" exact component={CourseDetailScreen} />
              <Route path="/courses/edit/:courseId" exact component={CourseEditScreen} />
              <Route path="/courses/delete/:courseId" exact component={CourseDeleteScreen} />
              <Route path="/courses/:courseId/lessons" exact component={LessonListScreen} />
              <Route path="/courses/:courseId/lessons/csv" exact component={LessonCreateBatchfromCSV} />
              <Route path="/courses/:courseId/lessons/new" exact component={LessonCreateScreen} />
              <Route path="/courses/:courseId/lessons/edit/:lessonId" exact component={LessonEditScreen} />
              <Route path="/courses/:courseId/lessons/delete/:lessonId" exact component={LessonDeleteScreen} />
              <Route path="/courses/:courseId/lessons/:lessonId" exact component={LessonDetailScreen} />
              {/* ここまでAuth外に移動 */}
              <Auth>
                <Switch>
                  <Route path="/settings" exact component={SettingsScreen} />
                  <Route path="/subscriptions" exact component={SubscriptionsScreen} />
                  <Route path="/dashboard" exact component={DashboardScreen} />
                  <Route path="/routemaps" exact component={RoutemapsListScreen} />
                  <Route path="/routemaps/new" exact component={RoutemapCreateScreen} />
                  <Route path="/routemaps/:routemapId" exact component={RoutemapDetailScreen} />
                </Switch>
              </Auth>
              <Route component={NotFound} />
            </Switch>
          </Router>
        </MuiThemeProvider>
      );
    }
  };

  render() {
    return <Fragment>{this.renderContent()}</Fragment>;
  }
}

const mapStateToProps = state => {
  return { isLoggedIn: state.auth.isLoggedIn, isAdmin: state.auth.isAdmin };
};

export default connect(
  mapStateToProps,
  { loggined, logouted, admin, notAdmin }
)(App);
