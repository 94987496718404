import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { fetchLessons, fetchCourse, progressChanged } from '../actions';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ReactMarkdown from 'react-markdown';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import 'video-react/dist/video-react.css'; // import css
import { Player, ControlBar, PlaybackRateMenuButton } from 'video-react';
import { TwitterShareButton, TwitterIcon } from 'react-share';
import { DialogActions } from '@material-ui/core';
import Payment from '../components/Payment';
import KeyboardArrowLeftOutlined from '@material-ui/icons/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlined from '@material-ui/icons/KeyboardArrowRightOutlined';

const styles = theme => ({
  exercise: {
    background: 'linear-gradient(transparent 80%, #FFFF00 0%)',
  },
  buttonContainer: {
    position: 'fixed',
    width: 250,
    bottom: 30,
    right: 30,
    [theme.breakpoints.down('xs')]: {
      width: 150,
      right: 30,
    },
  },
  leftButton: {
    width: '10%',
    marginRight: '5%',
    marginTop: 10,
    transition: '0.5s',
    textDecoration: 'none',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: '#e7eaef',
    },
    '&:focus': {
      backgroundColor: 'f9fbfe',
    },
    '&:active': {
      background: '#e7eaef',
    },
  },
  leftDiv: {
    width: '10%',
    marginRight: '5%',
    marginTop: 10,
    transition: '0.5s',
    textDecoration: 'none',
    textAlign: 'center',
  },
  rightButton: {
    width: '10%',
    marginLeft: '5%',
    marginTop: 10,
    transition: '0.5s',
    textDecoration: 'none',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: '#e7eaef',
    },
    '&:focus': {
      backgroundColor: 'f9fbfe',
    },
    '&:active': {
      background: '#e7eaef',
    },
  },
  balloon1: {
    position: 'fixed',
    width: 170,
    bottom: 50,
    right: 30,
    color: '#555',
    fontSize: '16px',
    background: '#e0edff',
  },
});

class LessonDetailScreen extends Component {
  state = {
    twitterDaialog: false,
    paymentDaialog: false,
    isComplete: false,
  };
  componentDidMount() {
    const { courseId } = this.props.match.params;
    document.onkeydown = this.keydown;
    this.props.fetchLessons(courseId);
    this.props.fetchCourse(courseId);
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }
  componentWillUnmount() {
    this.postProgress();
  }

  handleClose = () => {
    this.setState({ twitterDaialog: false });
  };

  handleClicktwitterDaialog = () => {
    this.setState({ twitterDaialog: true });
  };

  handleClickPaymentDaialog = () => {
    this.setState({ paymentDaialog: true });
  };

  paymentClose = () => {
    this.setState({ paymentDaialog: false });
  };

  renderPaymentDialog = () => {
    // 有料コースのダイアログ
    return (
      <Dialog open={this.state.paymentDaialog} onClose={this.paymentClose} fullWidth maxWidth={'sm'}>
        <DialogTitle>これ以降のレッスンは有料会員限定です</DialogTitle>
        <DialogContent>
          <Typography gutterBottom variant="subtitle1">
            有料会員はすべてのコースを受講できます
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.paymentClose} variant="contained" color="default">
            閉じる
          </Button>
          <Link to={'/subscriptions'} style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="primary">
              有料会員に登録する
            </Button>
          </Link>
        </DialogActions>
      </Dialog>
    );
  };

  renderShareButtonDaialog = () => {
    const { courseId } = this.props.match.params;
    const currentCourse = this.props.courses[courseId];
    if (!currentCourse) {
      return;
    }
    return (
      <Dialog open={this.state.twitterDaialog} onClose={this.handleClose} fullWidth maxWidth={'sm'}>
        <DialogTitle>おめでとうございます。コースを完了しました！</DialogTitle>
        <DialogContent>
          <Typography gutterBottom variant="subtitle1">
            Twitterで喜びを共有しよう！
          </Typography>
          <TwitterShareButton
            style={{ marginTop: 20, width: 64 }}
            title={`${currentCourse.title}を完了しました！`}
            hashtags={['cgcamp']}
            url={'https//cgcamp.work'}
          >
            <TwitterIcon size={64} round />
          </TwitterShareButton>
        </DialogContent>
        <DialogActions>
          <Link to={'/courses'} style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="default">
              コースの一覧に戻る
            </Button>
          </Link>
        </DialogActions>
      </Dialog>
    );
  };

  postProgress = () => {
    // userのデータに進捗を記録
    const { lessonId, courseId } = this.props.match.params;
    const currentLesson = this.props.lessons[lessonId];
    const lessonsLength = Object.keys(this.props.lessons).length;
    const courseData = this.props.courses[courseId];
    if (!currentLesson) {
      return;
    }
    const data = {
      ...courseData,
      courseId,
      lessonId,
      allLessonsList: lessonsLength,
      progress: currentLesson.order,
      complete: this.state.isComplete,
    };

    this.props.progressChanged(data);
  };

  renderEditButton = () => {
    const { lessonId, courseId } = this.props.match.params;
    let editButton;
    if (this.props.isAdmin) {
      editButton = (
        <Link to={`/courses/${courseId}/lessons/edit/${lessonId}`} style={{ textDecoration: 'none' }}>
          <Button variant="contained" color="default">
            EDIT
          </Button>
        </Link>
      );
    }

    return <div style={{ position: 'fixed', width: 250, bottom: 30, right: 30 }}>{editButton}</div>;
  };

  renderBodyContent = () => {
    const { lessonId } = this.props.match.params;
    const lesson = this.props.lessons[lessonId];
    if (lesson.type === 'image') {
      // 画像もしくは動画を配置
      const urlsLength = lesson.urls.length;
      return lesson.urls.map(url => {
        let caption;
        if (lesson.caption) {
          caption = (
            <blockquote>
              <ReactMarkdown source={lesson.caption} escapeHtml={false} linkTarget="_blank" />
            </blockquote>
          );
        }
        return (
          <Grid item key={url} xs={12 / urlsLength} style={{ textAlign: 'center' }}>
            <div style={{ maxWidth: 1080, margin: 'auto' }}>
              <img style={{ maxWidth: '100%', height: 'auto' }} alt={lesson.title} src={url} />
            </div>
            {caption}
          </Grid>
        );
      });
    } else {
      return (
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <div style={{ maxWidth: 1080, margin: 'auto' }}>
            <Player
              preload={'auto'}
              key={lesson.urls[0]}
              ref={player => {
                this.player = player;
              }}
            >
              <ControlBar>
                <PlaybackRateMenuButton order={7} rates={[2, 1.5, 1.0, 0.5]} />
              </ControlBar>
              <source src={lesson.urls[0]} type="video/mp4" />
            </Player>
          </div>
        </Grid>
      );
    }
  };

  renderExercises = () => {
    const { lessonId } = this.props.match.params;
    const lesson = this.props.lessons[lessonId];
    if (!lesson.exercisesFiles) {
      return;
    }
    if (lesson.exercisesFiles.length === 0) {
      return <br />;
    } else {
      return (
        <div>
          <a href={lesson.exercisesFiles[0]} download={lesson.title}>
            演習ファイルダウンロード
          </a>
        </div>
      );
    }
  };

  renderPrevButton = () => {
    const { courseId, lessonId } = this.props.match.params;
    const prevId = parseInt(lessonId) - 1;
    if (prevId === 0) {
      return <div className={this.props.classes.leftDiv}></div>;
    }
    return (
      <Link className={this.props.classes.leftButton} to={`/courses/${courseId}/lessons/${prevId}`}>
        <div style={{ marginTop: '50vh' }}>
          <KeyboardArrowLeftOutlined fontSize="large" />
        </div>
      </Link>
    );
  };

  renderNextButton = () => {
    const { courseId, lessonId } = this.props.match.params;
    const nextId = parseInt(lessonId) + 1;
    if (nextId > Object.keys(this.props.lessons).length) {
      return (
        <div
          className={this.props.classes.rightButton}
          onClick={() => this.setState({ twitterDaialog: true, isComplete: true })}
        >
          <KeyboardArrowRightOutlined fontSize="large" style={{ marginTop: '50vh' }} />
        </div>
      );
    }
    if (nextId === 2) {
      return (
        <Link className={this.props.classes.rightButton} to={`/courses/${courseId}/lessons/${nextId}`}>
          <div className={this.props.classes.balloon1}>
            <p>
              <KeyboardArrowRightOutlined fontSize="small" />
              をクリックするか、キーボードの→で次のレッスンに進みます
            </p>
          </div>
          <div style={{ marginTop: '50vh' }}>
            <KeyboardArrowRightOutlined fontSize="large" />
          </div>
        </Link>
      );
    }
    return (
      <Link className={this.props.classes.rightButton} to={`/courses/${courseId}/lessons/${nextId}`}>
        <div style={{ marginTop: '50vh' }}>
          <KeyboardArrowRightOutlined fontSize="large" />
        </div>
      </Link>
    );
  };

  keydown = e => {
    if (e.keyCode === 13) {
      // Enterで動画開始
      if (!this.player) {
        return;
      }
      const { player } = this.player.getState();
      player.paused ? this.player.play() : this.player.pause();
    }
    const { lessonId } = this.props.match.params;
    const nextId = parseInt(lessonId) + 1;
    const prevId = parseInt(lessonId) - 1;
    if (e.keyCode === 39) {
      if (nextId > Object.keys(this.props.lessons).length) {
        this.setState({ twitterDaialog: true, isComplete: true });
        return;
      }
      this.props.history.push(`${nextId}`);
    }
    if (e.keyCode === 37) {
      if (prevId === 0) {
        return;
      }
      this.props.history.push(`${prevId}`);
    }
  };

  render() {
    const { courseId, lessonId } = this.props.match.params;
    if (!this.props.lessons[lessonId] || !this.props.courses[courseId]) {
      return <div> lesson detail loading</div>;
    }
    if (!this.props.courses[courseId].isFree) {
      // コースが有料でユーザーが未課金かつレッスンが4以上の場合表示
      if (parseInt(lessonId) >= 4 && this.props.user.status !== 'active') {
        return <Payment />;
      }
    }
    const lesson = this.props.lessons[lessonId];
    let exerciseClass;
    if (lesson.title.indexOf('演習') !== -1) {
      // 演習ならアンダーライン追加
      exerciseClass = this.props.classes.exercise;
    }
    document.title = `${this.props.courses[courseId].title} | 初心者から3DCGを習得できるサービスCGCAMP`;

    return (
      <div style={{ height: '100vh', display: 'flex', flexDirection: 'row' }}>
        {this.renderPrevButton()}
        <div style={{ width: '70%', margin: 0 }}>
          <div>
            <Typography gutterBottom variant="subtitle1" component="h4" style={{ marginTop: 10 }}>
              LESSON {lesson.order}
            </Typography>
            <Typography variant="h4" component="h4" className={exerciseClass}>
              {lesson.title}
            </Typography>
            {this.renderExercises()}
            <div key={lesson.title} style={{ height: 120, overflowY: 'scroll' }}>
              <ReactMarkdown source={lesson.description} escapeHtml={false} linkTarget="_blank" />
            </div>
          </div>
          <div style={{ marginTop: 10 }}>
            <Grid container>{this.renderBodyContent()}</Grid>
          </div>
        </div>
        <div style={{ position: 'fixed', width: 250, bottom: 30, left: 30 }}>
          <Link
            to={{ pathname: `/courses/${courseId}/lessons/`, lessonId: lessonId }}
            style={{ textDecoration: 'none' }}
          >
            <Button variant="contained" color="default">
              Lesson 一覧
            </Button>
          </Link>
        </div>
        {this.renderNextButton()}
        <div>
          {this.renderEditButton()} {this.renderShareButtonDaialog()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    lessons: state.lessons,
    courses: state.courses,
    isAdmin: state.auth.isAdmin,
    user: state.auth.user,
  };
};

const StyledLessonDetailScreen = withStyles(styles)(LessonDetailScreen);

export default connect(
  mapStateToProps,
  { fetchLessons, progressChanged, fetchCourse }
)(StyledLessonDetailScreen);
