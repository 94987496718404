import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import Course from '../components/Course';
import { Link } from 'react-router-dom';
import { fetchRoutemaps } from '../actions';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import _ from 'lodash';

class RoutemapsListScreen extends Component {
  componentDidMount() {
    this.props.fetchRoutemaps();
  }

  renderList = () => {
    const routemaps = this.props.routemaps;
    this.routemapsList = _.toArray(routemaps);
    return this.routemapsList.map(currentRoutemap => (
      <Grid key={currentRoutemap.routemapId} item xs={12} sm={6} md={4} lg={3}>
        <Link to={`/routemaps/${currentRoutemap.routemapId}`} style={{ textDecoration: 'none' }}>
          <Course course={currentRoutemap} />
        </Link>
      </Grid>
    ));
  };

  render() {
    return (
      <Container>
        {this.props.routemaps ? (
          <div>
            <div style={{ textAlign: 'center', marginTop: 40 }}>
              <Typography gutterBottom variant="h4" component="h2">
                受講ルート一覧
              </Typography>
              <Typography gutterBottom variant="body2">
                各分野のプロフェッショナルになろう！
              </Typography>
            </div>
            <Grid container justify="center" spacing={4} style={{ marginTop: 20 }}>
              {this.renderList()}
            </Grid>

            <Link to={`/routemaps/new`} style={{ textDecoration: 'none' }}>
              新しくコースを作成する
            </Link>
          </div>
        ) : (
          'No routemaps found'
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return { routemaps: state.routemaps };
};

export default connect(
  mapStateToProps,
  { fetchRoutemaps }
)(RoutemapsListScreen);
