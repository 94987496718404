import React, { Component } from 'react';
import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Footer from '../components/Footer';
import { userEmailChanged, userDisplayNameChanged } from '../actions';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100% + 300px)',
  },
});

function SettingsScreen(props) {
  document.title = 'アカウント情報 | 初心者から3DCGを習得できるサービスCGCAMP';
  const classes = useStyles();
  const { image, displayName, email } = props.user;
  return (
    <div className={classes.wrapper}>
      <Container maxWidth="sm">
        <div style={{ textAlign: 'center', marginTop: '13em', height: '100px' }}>
          <Avatar style={{ margin: 'auto', width: 100, height: 100 }} alt="Remy Sharp" src={image} />
          <div>
            <TextField
              id="standard-email"
              label="ユーザー名"
              value={displayName}
              // onChange={text => this.props.userDisplayNameChanged(text.target.value)}
              margin="normal"
            />
          </div>
          <div>
            <TextField
              id="standard-email"
              label="メールアドレス"
              value={email}
              // onChange={text => this.props.userEmailChanged(text.target.value)}
              margin="normal"
            />
          </div>
        </div>
      </Container>
      <Footer />
    </div>
  );
}

const mapStateToProps = state => {
  return { user: state.auth.user };
};

export default connect(
  mapStateToProps,
  { userEmailChanged, userDisplayNameChanged }
)(SettingsScreen);
