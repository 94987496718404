import React, { useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import firebase from '../config/firebase';

import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import LoginForm from '../components/LoginForm';
import logo from './images/logo.svg';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  left: {
    display: 'inline',
    marginRight: 20,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  right: {
    display: 'inline',
  },
  dialogTitle: {
    textAlign: 'center',
  },
  title: {
    display: 'inline',
    marginRight: 50,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  courses: {},
}));

function NavBar(props) {
  const classes = useStyles();
  const [daialogOpen, setDaialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [formText, setFormText] = useState('ログイン');

  const handleClickSignUpOpen = () => {
    setDaialogOpen(true);
    setFormText('新規登録');
  };
  const handleClickLoginOpen = () => {
    setDaialogOpen(true);
    setFormText('ログイン');
  };

  const handleClose = () => {
    setDaialogOpen(false);
    setAnchorEl(null);
  };

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const logout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        console.log('ログアウトしました');
      })
      .catch(error => {
        console.log(`ログアウト時にエラーが発生しました (${error})`);
      });
  };

  const onFormTextSignUp = () => {
    setFormText('新規登録');
  };

  const onFormTextLogin = () => {
    setFormText('ログイン');
  };

  const renderSwitchLoginOrSignUp = () => {
    if (formText === 'ログイン') {
      return (
        <div style={{ textAlign: 'center', margin: 20 }}>
          アカウントをお持ちでないですか？
          <span onClick={onFormTextSignUp} style={{ color: 'rgb(0, 112, 210)', cursor: 'pointer' }}>
            新規登録
          </span>
        </div>
      );
    } else {
      return (
        <div style={{ textAlign: 'center', margin: 20 }}>
          すでにアカウントをお持ちですか？{' '}
          <span onClick={onFormTextLogin} style={{ color: 'rgb(0, 112, 210)', cursor: 'pointer' }}>
            ログイン
          </span>
        </div>
      );
    }
  };

  const renderDialog = () => {
    // ログアウトしてたらログインフォームをダイアログ表示
    return (
      <Fragment>
        <div>
          <Button color="default" onClick={handleClickLoginOpen}>
            ログイン
          </Button>
        </div>
        <div>
          <Button color="default" onClick={handleClickSignUpOpen}>
            無料登録
          </Button>
        </div>
        <Dialog open={daialogOpen} onClose={handleClose} fullWidth maxWidth={'sm'}>
          <DialogTitle className={classes.dialogTitle}>{formText} </DialogTitle>
          <DialogContent>
            <LoginForm formText={formText} />
            {renderSwitchLoginOrSignUp()}
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  };

  const renderLogout = () => {
    // ログインしてたら表示
    const open = Boolean(anchorEl);
    let userIcon;
    if (props.user.image) {
      // icon画像があれば表示
      userIcon = <Avatar alt="Remy Sharp" src={props.user.image} />;
    } else {
      // 画像がないならフォントを表示
      userIcon = <AccountCircle fontSize="large" />;
    }
    return (
      <div>
        <IconButton
          aria-label="Account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          {userIcon}
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleClose}
        >
          <Link to={`/dashboard`} style={{ textDecoration: 'none', color: 'black' }}>
            <MenuItem onClick={handleClose}>ダッシュボード</MenuItem>
          </Link>
          <Link to={`/settings`} style={{ textDecoration: 'none', color: 'black' }}>
            <MenuItem onClick={handleClose}>アカウント情報</MenuItem>
          </Link>
          <MenuItem
            onClick={() => {
              logout();
              handleClose();
            }}
          >
            ログアウト
          </MenuItem>
        </Menu>
      </div>
    );
  };

  let authButton;
  if (props.isLoggedIn) {
    authButton = renderLogout();
  } else {
    authButton = renderDialog();
  }

  return (
    <AppBar position="static" color="inherit">
      <Toolbar>
        <div className={classes.root}>
          <div className={classes.left}>
            <Link to={`/dashboard`} style={{ textDecoration: 'none', color: 'black' }}>
              <img src={logo} height="30px" />
            </Link>
          </div>
          <div className={classes.right}>
            <Link to={`/courses`} style={{ textDecoration: 'none', color: 'black' }}>
              <Button color="default">コース一覧</Button>
            </Link>
          </div>

          {/* <Link to={`/routemaps`} style={{ textDecoration: 'none', color: 'black' }}>
            <Typography style={{ display: 'inline', marginRight: 20 }} variant="subtitle1">
              受講ルート
            </Typography>
          </Link> */}
        </div>
        {authButton}
      </Toolbar>
    </AppBar>
  );
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(NavBar);
