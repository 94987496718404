import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Footer from '../components/Footer';

const useStyles = makeStyles({
  container: {
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    maxWidth: '740px !important',
    padding: '40px 20px',
  },
  title: {
    color: 'rgb(68, 68, 68)',
    fontWeight: '900',
    fontSize: '24px',
    textAlign: 'center',
    marginBottom: '36px',
  },
  subtitle: {
    color: 'rgb(68, 68, 68)',
    fontWeight: '900',
    fontSize: '16px',
    textAlign: 'left',
    marginBottom: '12px',
    borderBottom: '1px solid rgb(238, 238, 238)',
  },
  description: {
    fontSize: '14px',
    lineHeight: '30px',
    marginBottom: '40px',
    color: 'rgb(68, 68, 68)',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100% + 300px)',
  },
});

function PrivacyPolicyScreen() {
  window.scrollTo(0, 0);
  document.title = 'プライバシーポリシー | 初心者から3DCGを習得できるサービスCGCAMP';
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.title}>プライバシーポリシー</div>
        <div className={classes.description}>
          CGCAMP運営チーム（以下「運営チーム」といいます。）は、提供するサービス（以下「本サービス」といいます。）における、ユーザーについての個人情報を含む利用者の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます。）を定めます。
        </div>
        <div className={classes.subtitle}>収集する利用者情報及びその収集方法</div>
        <div className={classes.description}>
          本サービスにおいて運営チームが収集する利用者情報は、その収集方法に応じて、以下のようなものとなります。
          <br />
          （1）ユーザーからご提供いただく情報
          <br />
          ・氏名
          <br />
          ・メールアドレス
          <br />
          ・その他運営チームが定める入力フォームにユーザーが入力する情報
          <br />
          <br />
          （2）ユーザーが本サービスを利用するにあたって、他サービスと連携を許可することにより、当該他サービスからご提供いただく情報
          <br />
          ・当該外部サービスでユーザーが利用するID
          <br />
          ・その他当該外部サービスのプライバシー設定によりユーザーが連携先に開示を認めた情報
          <br />
          <br />
          （3）Googleアナリティクスから収集するユーザー属性情報
          <br />
          当サイトでは、Googleが提供している分析ツール「Google Analytics」を利用して、訪問者の行動を分析しています。
          このGoogle
          Analyticsはトラフィックデータの収集のためにCookieを使用しています。このトラフィックデータは匿名で収集されており、個人を特定するものではありません。この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。
          Googleアナリティクスの詳細についてはGoogle アナリティクス サービス利用規約をご確認ください。
        </div>
        <div className={classes.subtitle}>利用目的</div>
        <div className={classes.description}>
          本サービスのサービス提供にかかわる利用者情報の具体的な利用目的は以下の通りです。
          <br />
          ・本サービスに関する登録の受付、本人確認等、本サービスの提供、維持、保護及び改善のため
          <br />
          ・本サービスに関するご案内、お客様からのお問い合わせ等への対応のため
          <br />
          ・本サービスに関する運営チームの規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対応するため
          <br />
          ・本サービスに関する規約等の変更などを通知するため
          <br />
          ・上記の利用目的に付随する利用目的のため
        </div>
        <div className={classes.subtitle}>第三者への開示・提供の禁止</div>
        <div className={classes.description}>
          運営チームは、利用者情報のうち、個人情報については、個人情報保護法その他の法令に基づき開示が認められる場合を除くほか、あらかじめユーザーの同意を得ないで、第三者に提供しません。但し、次に掲げる場合はこの限りではありません。
          <br />
          ・運営チームが利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
          <br />
          ・合併その他の事由による事業の承継に伴って個人情報が提供される場合
          <br />
          ・第4項の定めに従って、情報収集モジュール提供者へ個人情報が提供される場合
          <br />
          ・国の機関もしくは地方公共団体またはその委託を受けたものが法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザーの同意を得ることによって当該事務の遂行に支障を及ぼす恐れがある場合
          <br />
          ・その他、個人情報保護法その他の法令で認められる場合
        </div>
        <div className={classes.subtitle}>ご本人の照会</div>
        <div className={classes.description}>
          お客さまがご本人の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、対応させていただきます。
        </div>
        <div className={classes.subtitle}>プライバシーポリシーの変更手続</div>
        <div className={classes.description}>
          運営チームは、利用者情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、本ポリシーを変更することがあります。
          本ポリシーの変更にあたって、運営チームが大幅な変更と判断した場合を除いて、ユーザーの皆様に通知をすることはございません。ご利用の際には随時、最新のプライバシーポリシーをご参照ください。
        </div>
        <div className={classes.subtitle}>お問い合せ</div>
        <div className={classes.description}>
          本サービスの個人情報の取扱に関するお問い合せは下記までご連絡ください。
          <br />
          <br />
          Email: cgcamp.gate@gmail.com
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicyScreen;
