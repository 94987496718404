import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    paddingBottom: '100px',
    paddingTop: '100px',
    textAlign: 'center',
  },
  title: {
    marginBottom: '30px',
  },
  description: {
    marginBottom: '10px',
  },
  button: {
    marginTop: '50px',
    marginBottom: '50px',
  },
});

function Payment() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <h2 className={classes.title}>このページは有料会員限定です</h2>
      <div className={classes.description}>このコースを受講するには有料プランに登録する必要があります。</div>
      <div className={classes.description}>有料プランに登録すると、全てのコースを受講できるようになります。</div>
      <div className={classes.button}>
        <Link to={'/subscriptions'} style={{ textDecoration: 'none' }}>
          <Button variant="contained" color="primary">
            有料プラン詳細
          </Button>
        </Link>
      </div>
      <div className={classes.description}>無料コースのまま学習を続ける方</div>
      <Link to={'/courses'} style={{ textDecoration: 'none' }}>
        コース一覧に戻る
      </Link>
    </div>
  );
}

export default Payment;
