import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { fetchCourse, fetchProgress, fetchCourses } from '../actions';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Rating from '@material-ui/lab/Rating';
import Lens from '@material-ui/icons/Lens';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import NotFound from '../components/NotFound';
import Footer from '../components/Footer';
import Course from '../components/Course';

const StyledRating = withStyles({
  iconFilled: {
    color: '#2286c3',
  },
  iconHover: {
    color: '#ff3d47',
  },
})(Rating);

const styles = theme => ({
  description: {
    marginLeft: 200,
    marginRight: 200,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      marginLeft: 100,
      marginRight: 100,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 50,
      marginRight: 50,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100% + 300px)',
  },
});

class CourseDetailScreen extends Component {
  componentDidMount() {
    const { courseId } = this.props.match.params;
    this.props.fetchCourse(courseId);
    this.props.fetchCourses();
    this.props.fetchProgress();
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  renderPrepCourses = prepCourses => {
    if (!prepCourses.length) {
      return;
    }

    return (
      <Fragment>
        <Typography variant="h5" gutterBottom style={{ marginBottom: 20 }}>
          このコースを始める前に理解しておきましょう
        </Typography>
        <Grid container spacing={1}>
          {prepCourses.map(currentCourse => {
            const { courseId } = currentCourse;
            return (
              <Grid key={courseId} item xs={12} sm={6}>
                <Link to={{ pathname: `/courses/${courseId}` }} style={{ textDecoration: 'none' }}>
                  <Course course={currentCourse} />
                </Link>
              </Grid>
            );
          })}
        </Grid>
      </Fragment>
    );
  };

  renderStartButton = () => {
    const { courseId } = this.props.match.params;
    return (
      <Box style={{ textAlign: 'center' }}>
        <Link to={`/courses/${courseId}/lessons/1`} style={{ textDecoration: 'none' }}>
          <Button variant="contained" color="primary">
            このコースを始める
          </Button>
        </Link>
      </Box>
    );
  };

  renderButton = () => {
    const { courseId } = this.props.match.params;
    // このコースが初めてのコース場合
    if (this.props.progress.length === 0) {
      return this.renderStartButton();
    }
    // リストをcourseIdをキーにしたオブジェクトにする
    const progressObject = _.keyBy(this.props.progress, 'courseId');
    if (!progressObject[courseId]) {
      return this.renderStartButton();
    }
    const progressLesson = progressObject[courseId].lessonId;
    const nextLesson = parseInt(progressLesson);
    // このコースをコンプリートしている場合
    if (progressObject[courseId].complete) {
      return (
        <Box style={{ textAlign: 'center' }}>
          <Link to={`/courses/${courseId}/lessons/1`} style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="primary">
              復習する
            </Button>
          </Link>
        </Box>
      );
    } else if (progressObject[courseId]) {
      return (
        <Box style={{ textAlign: 'center' }}>
          <Link to={`/courses/${courseId}/lessons/${nextLesson}`} style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="primary">
              このコースの続きから始める
            </Button>
          </Link>
        </Box>
      );
    } else {
      return this.renderStartButton();
    }
  };

  renderFinishedProduct = () => {
    const { courseId } = this.props.match.params;
    const course = this.props.courses[courseId];

    if (course.productImageUrl) {
      return (
        <Fragment>
          <Typography variant="h5" gutterBottom style={{ marginBottom: 20 }}>
            このコースの完成品
          </Typography>
          <img src={course.productImageUrl} alt={course.title} style={{ maxWidth: '100%', maxHeight: 'auto' }} />
        </Fragment>
      );
    } else {
      return <Fragment></Fragment>;
    }
  };

  render() {
    const { courseId } = this.props.match.params;
    if (Object.keys(this.props.courses).length) {
      if (!this.props.courses[courseId]) {
        return <div>loading</div>;
      }
      if (!this.props.courses[courseId].title) {
        return <NotFound />;
      }
      const { title, description, time_required, difficulty, prepCourses, softs } = this.props.courses[courseId];

      let softsStr;
      if (softs) {
        softsStr = softs.join(' ');
      }
      document.title = `${this.props.courses[courseId].title} | 初心者から3DCGを習得できるサービスCGCAMP`;
      return (
        <div className={this.props.classes.wrapper}>
          <Grid container>
            <Grid item xs={1} sm={1} md={2}></Grid>
            <Grid
              item
              xs={10}
              sm={10}
              md={8}
              style={{
                backgroundColor: '#b3e5fc',
                borderRadius: 30,
                padding: 30,
                marginTop: 30,
              }}
            >
              <Box
                bgcolor="primary.dark"
                color="primary.contrastText"
                style={{ width: 55, borderRadius: 5, margin: 'auto' }}
              >
                {/* <Typography variant="subtitle1" gutterBottom style={{ textAlign: 'center' }}>
                Free
              </Typography> */}
              </Box>
              <Typography variant="h4" component="h2" gutterBottom style={{ marginBottom: 20, textAlign: 'center' }}>
                {title}
              </Typography>
              <Typography variant="subtitle1" gutterBottom className={this.props.classes.description}>
                {description}
              </Typography>

              <Box display="flex" flexDirection="row" justifyContent="center">
                <Box m={2}>
                  <Typography variant="caption" gutterBottom>
                    修了時間
                  </Typography>
                  <Typography variant="body1" gutterBottom style={{ marginBottom: 30 }}>
                    {time_required}時間
                  </Typography>
                </Box>
                <Box m={2}>
                  <Typography variant="caption" gutterBottom>
                    難易度
                    <StyledRating
                      readOnly
                      size="small"
                      name="customized-color"
                      value={difficulty}
                      icon={<Lens fontSize="inherit" />}
                    />
                  </Typography>
                </Box>
                <Box m={2}>
                  <Typography variant="caption" gutterBottom>
                    使用ソフト
                  </Typography>
                  <Typography variant="body1" gutterBottom style={{ marginBottom: 30 }}>
                    {softsStr}
                  </Typography>
                </Box>
              </Box>
              {this.renderButton()}
            </Grid>
            <Grid item xs={1} sm={1} md={2}></Grid>

            <Grid item xs={12} sm={12} md={6} style={{ padding: 50 }}>
              {this.renderFinishedProduct()}
            </Grid>

            <Grid item xs={12} sm={12} md={6} style={{ padding: 50 }}>
              {this.renderPrepCourses(prepCourses)}
            </Grid>
          </Grid>
          <Footer />
        </div>
      );
    } else {
      return <Fragment>Loading</Fragment>;
    }
  }
}

const mapStateToProps = state => {
  return { courses: state.courses, progress: state.progress };
};

const StyledCourseDetailScreen = withStyles(styles)(CourseDetailScreen);

export default connect(
  mapStateToProps,
  { fetchCourse, fetchProgress, fetchCourses }
)(StyledCourseDetailScreen);
