import { PROGRESS_CHANGED, FETCH_PROGRESS, LOGIN_USER_FAIL } from '../actions/types';

export default (state = [], action) => {
  switch (action.type) {
    case PROGRESS_CHANGED:
      return state;
    case FETCH_PROGRESS:
      return action.payload;
    case LOGIN_USER_FAIL:
      return [];
    default:
      return state;
  }
};
