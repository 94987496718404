import {
  EMAIL_CHANGED,
  PASSWORD_CHANGED,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGIN_USER,
  USER_EMAIL_CHANGED,
  USER_DISPLAYNAME_CHANGED,
  ADMIN_USER_SUCCESS,
  ADMIN_USER_FAIL,
  FETCH_USER,
  SIGN_UP_USER,
} from '../actions/types';

const INITIAL_STATE = {
  email: '',
  password: '',
  user: null,
  error: '',
  loading: false,
  isLoggedIn: null,
  isAdmin: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EMAIL_CHANGED:
      return {
        ...state,
        email: action.payload,
      };
    case PASSWORD_CHANGED:
      return {
        ...state,
        password: action.payload,
      };
    case LOGIN_USER:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case SIGN_UP_USER:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        isLoggedIn: true,
      };
    case LOGIN_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        password: '',
        isLoggedIn: false,
      };
    case USER_EMAIL_CHANGED:
      return {
        ...state,
        user: {
          ...state.user,
          email: action.payload,
        },
      };
    case USER_DISPLAYNAME_CHANGED:
      return {
        ...state,
        user: {
          ...state.user,
          displayName: action.payload,
        },
      };
    case ADMIN_USER_SUCCESS:
      return {
        ...state,
        isAdmin: true,
      };
    case ADMIN_USER_FAIL:
      return {
        ...state,
        isAdmin: false,
      };
    case FETCH_USER:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};
