import firebase, { db } from '../config/firebase';

import { PROGRESS_CHANGED } from './types';

export const progressChanged = data => async dispatch => {
  const user = firebase.auth().currentUser;
  if (!user) {
    return;
  }

  data.progressTime = Date.now();
  await db
    .collection('users')
    .doc(user.uid)
    .collection('progress')
    .doc(data.courseId)
    .set(data);
  dispatch({
    type: PROGRESS_CHANGED,
  });
};
