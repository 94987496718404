import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import { connect } from 'react-redux';
import { fetchCourses } from '../../actions';
import _ from 'lodash';
import NotFound from '../../components/NotFound';

class CourseForm extends Component {
  state = this.props.initialState;

  componentDidMount() {
    this.props.fetchCourses();
  }

  prepCoursesChange = event => {
    // コースIDをリストで保存する
    this.setState({ prepCourses: event.target.value });
  };

  eyecatchChangeFile = event => {
    event.preventDefault();
    this.setState({ eyecatchImage: event.target.files[0] });
    const createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL;
    const image_src = createObjectURL(event.target.files[0]);
    this.setState({ image_src });
  };

  productImageChangeFile = event => {
    event.preventDefault();
    this.setState({ productImage: event.target.files[0] });
  };

  onSubmit = () => {
    const {
      eyecatchImage,
      title,
      description,
      difficulty,
      softs,
      time_required,
      prepCourses,
      isPublished,
      imageNames,
      isFree,
      productImage,
    } = this.state;
    let softsList;
    if (softs.length) {
      softsList = softs.split(',');
    } else {
      softsList = [];
    }
    // コースidからコースのリストを作成する
    const prepCoursesList = prepCourses.map(id => this.props.courses[id]);
    this.props.onSubmit(this.props.courseId, eyecatchImage, productImage, {
      title,
      description,
      difficulty,
      time_required,
      softs: softsList,
      prepCourses: prepCoursesList,
      isPublished,
      imageNames,
      isFree,
    });
    this.setState({
      title: '',
      description: '',
      difficulty: 1,
      time_required: 1,
      eyecatchImage: null,
      productImage: null,
      prepCourses: [],
      softs: [],
      image_src: null,
      isFree: true,
    });
  };

  isPublishedChange = event => {
    this.setState({ isPublished: event.target.checked });
  };

  isFreeChange = event => {
    this.setState({ isFree: event.target.checked });
  };

  renderPrepCourses = () => {
    const coursesList = _.toArray(this.props.courses);
    if (coursesList.length) {
      return (
        <div style={{ textAlign: 'center' }}>
          <InputLabel htmlFor="select-multiple">prepCourses</InputLabel>
          <Select
            multiple
            value={this.state.prepCourses}
            onChange={this.prepCoursesChange}
            input={<Input id="select-multiple" />}
          >
            {coursesList.map(course => (
              <MenuItem key={course.courseId} value={course.courseId}>
                {course.title}
              </MenuItem>
            ))}
          </Select>
        </div>
      );
    }
  };

  render() {
    if (!this.props.isAdmin) {
      return <NotFound />;
    }
    return (
      <Container maxWidth="sm">
        <div style={{ textAlign: 'center' }}>
          <TextField
            id="standard-title"
            label="title"
            value={this.state.title}
            onChange={text => this.setState({ title: text.target.value })}
            margin="normal"
          />
        </div>
        <div style={{ textAlign: 'center' }}>
          <TextField
            id="outlined-description-flexible"
            label="description"
            multiline
            rowsMax="20"
            value={this.state.description}
            onChange={text => this.setState({ description: text.target.value })}
            margin="normal"
            variant="outlined"
          />
        </div>
        <div style={{ textAlign: 'center' }}>
          <div>ソフト名をカンマ区切りで入力</div>
          <TextField
            id="outlined-softs-flexible"
            label="softs"
            multiline
            rowsMax="20"
            value={this.state.softs}
            onChange={text => this.setState({ softs: text.target.value })}
            margin="normal"
            variant="outlined"
          />
        </div>
        <div style={{ textAlign: 'center' }}>
          <InputLabel htmlFor="time_require-simple">time_required</InputLabel>
          <Select
            value={this.state.time_required}
            onChange={text => this.setState({ time_required: text.target.value })}
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={9}>9</MenuItem>
            <MenuItem value={10}>10</MenuItem>
          </Select>
        </div>
        <div style={{ textAlign: 'center' }}>
          <InputLabel htmlFor="difficulty-simple">difficulty</InputLabel>
          <Select value={this.state.difficulty} onChange={text => this.setState({ difficulty: text.target.value })}>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
          </Select>
        </div>
        <div style={{ textAlign: 'center' }}>
          <div>アイキャッチ画像</div>
          <input
            style={{ display: 'none' }}
            id="eyecatch"
            accept={'*'}
            type="file"
            onChange={this.eyecatchChangeFile}
          />
          <label htmlFor="eyecatch">
            <Button variant="outlined" component="span">
              ファイルを選択
            </Button>
          </label>
        </div>

        <div style={{ textAlign: 'center' }}>
          <div>プロダクト完成画像</div>
          <input
            style={{ display: 'none' }}
            id="productImage"
            accept={'*'}
            type="file"
            onChange={this.productImageChangeFile}
          />
          <label htmlFor="productImage">
            <Button variant="outlined" component="span">
              ファイルを選択
            </Button>
          </label>
        </div>

        {this.renderPrepCourses()}

        <div style={{ textAlign: 'center' }}>
          <div>isPublished チェックを入れたら一般公開</div>
          <Switch
            checked={this.state.isPublished}
            onChange={this.isPublishedChange}
            value="isPublished"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </div>
        <div style={{ textAlign: 'center' }}>
          <div>isFree チェックを入れたらFreeコース</div>
          <Switch
            checked={this.state.isFree}
            onChange={this.isFreeChange}
            value="isPublished"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </div>

        <div style={{ textAlign: 'center' }}>
          <Button onClick={this.onSubmit} variant="contained" color="primary">
            create
          </Button>
        </div>
        <div style={{ textAlign: 'center' }}>
          <img alt={this.state.title} src={this.state.image_src} width={500} />
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return { courses: state.courses, isAdmin: state.auth.isAdmin };
};

export default connect(
  mapStateToProps,
  { fetchCourses }
)(CourseForm);
