import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createLesson } from '../../actions';
import LessonFrom from './LessonForm';

class LessonCreateScreen extends Component {
  onSubmit = (courseId, lessonId, files, data) => {
    this.props.createLesson(courseId, lessonId, files, data);
  };
  render() {
    const { courseId } = this.props.match.params;
    let initialOrder;
    if (this.props.location.state) {
      initialOrder = this.props.location.state.order;
    }
    const initialState = {
      title: '',
      description: '',
      type: 1,
      files: null,
      order: initialOrder,
      previewimage: null,
      fileNames: [],
      caption: '',
    };

    return (
      <Fragment>
        <LessonFrom initialState={initialState} onSubmit={this.onSubmit} courseId={courseId} />
      </Fragment>
    );
  }
}

export default connect(
  null,
  { createLesson }
)(LessonCreateScreen);
