import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { fetchProgress } from '../actions';
import { withStyles } from '@material-ui/styles';
import Footer from '../components/Footer';
import DashboardCourse from '../components/DashboardCourse';

const styles = theme => ({
  dialogTitle: {
    textAlign: 'center',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100% + 300px)',
  },
  box: {
    width: '60%',
    margin: 'auto',
    marginTop: 70,
    marginBottom: 100,
    [theme.breakpoints.down('xs')]: {
      width: '80%',
    },
  },
});

class DashboardScreen extends Component {
  componentDidMount() {
    this.props.fetchProgress();
    document.title = 'ダッシューボード | 初心者から3DCGを習得できるサービスCGCAMP';
  }

  renderCompleteList = () => {
    return this.props.progress.map(course => {
      if (course.complete) {
        return (
          <Fragment key={course.courseId}>
            <DashboardCourse course={course} />
          </Fragment>
        );
      } else {
        return;
      }
    });
  };

  renderEnrollmentList = () => {
    const progressList = this.props.progress.slice(1);
    return progressList.map(course => {
      if (!course.complete) {
        return (
          <Fragment key={course.courseId}>
            <DashboardCourse course={course} />
          </Fragment>
        );
      } else {
        return;
      }
    });
  };

  render() {
    if (this.props.progress.length > 0) {
      return (
        <div className={this.props.classes.wrapper}>
          <Box display="flex" flexDirection="column" justifyContent="center" className={this.props.classes.box}>
            <Box m={1}>
              <Typography gutterBottom variant="h6" component="h2">
                前回の続き
              </Typography>
              <DashboardCourse course={this.props.progress[0]} />
            </Box>
            <Box m={1} style={{ marginTop: 80 }}>
              <Typography gutterBottom variant="h6" component="h2">
                学習中のレッスン
              </Typography>
              {this.renderEnrollmentList()}
            </Box>
            <Box m={1} style={{ marginTop: 50 }}>
              <Typography gutterBottom variant="h6" component="h2">
                完了したレッスン
              </Typography>
              {this.renderCompleteList()}
            </Box>
          </Box>
          <Footer />
        </div>
      );
    } else {
      return (
        <div className={this.props.classes.wrapper}>
          <div style={{ textAlign: 'center', marginTop: '13em', height: '100px' }}>
            <h3>まずコースを先に始めてみよう！</h3>
          </div>
          <Footer />
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return { progress: state.progress };
};

const StyledDashboardScreen = withStyles(styles)(DashboardScreen);

export default connect(
  mapStateToProps,
  { fetchProgress }
)(StyledDashboardScreen);
