import { FETCH_COURSES, FETCH_COURSE } from '../actions/types';

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_COURSES:
      return action.payload;
    case FETCH_COURSE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
