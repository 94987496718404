export const EMAIL_CHANGED = 'email_changed';
export const PASSWORD_CHANGED = 'password_changed';
export const LOGIN_USER_SUCCESS = 'login_user_success';
export const LOGIN_USER_FAIL = 'login_user_fail';
export const LOGIN_USER = 'login_user';
export const FETCH_COURSES = 'fetch_courses';
export const FETCH_COURSE = 'fetch_course';
export const CREATE_COURSE = 'create_course';
export const CREATE_LESSON = 'create_lesson';
export const FETCH_LESSONS = 'fetch_lessons';
export const USER_EMAIL_CHANGED = 'user_email_changed';
export const USER_DISPLAYNAME_CHANGED = 'user_displayname_changed';
export const PROGRESS_CHANGED = 'progress_changed';
export const FETCH_PROGRESS = 'fetch_progress';
export const CREATE_ROUTEMAP = 'create_routemap';
export const FETCH_ROUTEMAPS = 'fetch_routemaps';
export const FETCH_ROUTEMAP = 'fetch_routemap';
export const ADMIN_USER_SUCCESS = 'admin_user_success';
export const ADMIN_USER_FAIL = 'admin_user_fail';
export const FETCH_USER = 'fetch_user';
export const SIGN_UP_USER = 'sign_up_user';
