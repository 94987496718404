import firebase, { db } from '../config/firebase';

import { FETCH_COURSES, FETCH_COURSE, FETCH_LESSONS, FETCH_PROGRESS, FETCH_ROUTEMAPS, FETCH_ROUTEMAP } from './types';

export const fetchCourses = () => async dispatch => {
  const response = await db
    .collection('courses')
    .orderBy('create_at', 'desc')
    .get();
  const courses = {};
  response.forEach(doc => {
    courses[doc.id] = {
      ...doc.data(),
      courseId: doc.id,
    };
  });
  dispatch({
    type: FETCH_COURSES,
    payload: courses,
  });
};

export const fetchCourse = courseId => async dispatch => {
  const doc = await db
    .collection('courses')
    .doc(courseId)
    .get();
  const courses = {};
  courses[doc.id] = {
    ...doc.data(),
    courseId: doc.id,
  };
  dispatch({
    type: FETCH_COURSE,
    payload: courses,
  });
};

export const fetchLessons = courseId => async dispatch => {
  const response = await db
    .collection('courses')
    .doc(courseId)
    .collection('lessons')
    .orderBy('order', 'asc')
    .get();
  const lessons = {};
  /*
  {order:
    {
    lessonId: doc.id
    title: hogehoge
    description: hogehoge
    }
  } にしてる
  */
  response.forEach(doc => {
    lessons[doc.data().order] = {
      ...doc.data(),
      lessonId: doc.id,
    };
  });
  dispatch({
    type: FETCH_LESSONS,
    payload: lessons,
  });
};

export const fetchProgress = () => async dispatch => {
  const user = firebase.auth().currentUser;
  if (!user) {
    return;
  }

  const response = await db
    .collection('users')
    .doc(user.uid)
    .collection('progress')
    .orderBy('progressTime', 'desc')
    .get();
  const progress = [];
  response.forEach(doc => {
    progress.push(doc.data());
  });
  dispatch({
    type: FETCH_PROGRESS,
    payload: progress,
  });
};

export const fetchRoutemaps = () => async dispatch => {
  const response = await db.collection('routemaps').get();
  const routemaps = {};
  response.forEach(doc => {
    routemaps[doc.id] = {
      ...doc.data(),
      routemapId: doc.id,
    };
  });
  dispatch({
    type: FETCH_ROUTEMAPS,
    payload: routemaps,
  });
};

export const fetchRoutemap = routemapId => async dispatch => {
  const doc = await db
    .collection('routemaps')
    .doc(routemapId)
    .get();
  const routemaps = {};
  routemaps[doc.id] = {
    ...doc.data(),
    courseId: doc.id,
  };
  dispatch({
    type: FETCH_ROUTEMAP,
    payload: routemaps,
  });
};
