import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import CoursesReducer from './CoursesReducer';
import LessonReducer from './LessonsReducer';
import ProgressReducer from './ProgressReducer';
import RoutemapReducer from './RoutemapReducer';

export default combineReducers({
  auth: AuthReducer,
  courses: CoursesReducer,
  lessons: LessonReducer,
  progress: ProgressReducer,
  routemaps: RoutemapReducer,
});
