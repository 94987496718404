import React, { Component, Fragment } from 'react';
import { createCourse } from '../../actions';
import CorseForm from './CourseForm';
import { connect } from 'react-redux';

export class CourseCreateScreen extends Component {
  onSubmit = (courseId, eyecatchImage, productImage, data) => {
    this.props.createCourse(courseId, eyecatchImage, productImage, data);
  };

  render() {
    const initialState = {
      title: '',
      description: '',
      difficulty: 1,
      time_required: 1,
      image: null,
      prepCourses: [],
      softs: [],
      image_src: '',
      isPublished: false,
      isFree: true,
    };
    return (
      <Fragment>
        <CorseForm initialState={initialState} onSubmit={this.onSubmit} />
      </Fragment>
    );
  }
}

export default connect(
  null,
  { createCourse }
)(CourseCreateScreen);
