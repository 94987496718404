import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Lens from '@material-ui/icons/Lens';
import Rating from '@material-ui/lab/Rating';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import QueryBuilder from '@material-ui/icons/QueryBuilder';
import EditOutlined from '@material-ui/icons/EditOutlined';

const StyledRating = withStyles({
  iconFilled: {
    color: '#2286c3',
  },
  iconHover: {
    color: '#ff3d47',
  },
})(Rating);

const useStyles = makeStyles({
  shadow: {
    boxShadow: '0px 0px 5px rgba(0,0,0,0.3)',
    transition: '0.5s',
    '&:hover, &:focus': {
      boxShadow: '0px 0px 30px rgba(0,0,0,0.3)',
    },
    '&:active': {
      boxShadow: '0px 0px 5px rgba(0,0,0,0.3)',
    },
  },
});

const Course = props => {
  const classes = useStyles();
  const { title, description, eyecatchImageUrl, softs, time_required, difficulty } = props.course;
  let softsStr;
  if (softs) {
    softsStr = softs.join(' ');
  }
  return (
    <Fragment>
      {props.course ? (
        <Card className={classes.shadow}>
          <CardMedia style={{ height: 130 }} image={eyecatchImageUrl} src="img" />
          <CardContent style={{ height: 200, marginTop: 10 }}>
            <Typography gutterBottom variant="h5" component="h2">
              {title}
            </Typography>
            <Box alignItems="center" display="flex" flexDirection="row">
              <Box>
                <QueryBuilder style={{ height: 15 }} />
              </Box>
              <Box style={{ marginRight: 20 }}>
                <Typography variant="caption">{time_required}時間</Typography>
              </Box>
              <Box>
                <Typography variant="caption">難易度</Typography>
              </Box>
              <Box>
                <StyledRating
                  readOnly
                  size="small"
                  name="customized-color"
                  value={difficulty}
                  icon={<Lens style={{ height: 13 }} fontSize="inherit" />}
                />
              </Box>
            </Box>

            <Typography style={{ marginTop: 10 }} gutterBottom variant="body2">
              {description}
            </Typography>
          </CardContent>
          <CardContent>
            <Typography variant="caption">使用ソフト </Typography>
            <Typography variant="caption">
              <EditOutlined style={{ height: 15 }} />
              {softsStr}
            </Typography>
          </CardContent>
        </Card>
      ) : null}
    </Fragment>
  );
};

export default Course;
