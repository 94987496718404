import { db, storage } from '../config/firebase';
import uniqid from 'uniqid';

const editLessonDatabase = async (courseId, lessonId, data) => {
  const dateForISO = new Date();
  data.update_at = dateForISO.toISOString();
  await db
    .collection('courses')
    .doc(courseId)
    .collection('lessons')
    .doc(lessonId)
    .update(data);
  console.log('Update Lesson Database');
};

export const editLesson = (courseId, lessonId, files, data) => async dispatch => {
  if (files) {
    // ファイル削除
    for (const fileName of data.fileNames) {
      storage
        .ref(`courses/lessons/${courseId}`)
        .child(fileName)
        .delete()
        .then(() => console.log(`delete ${fileName}`));
    }
    // ファイルアップロード
    data.urls = [];
    data.fileNames = [];
    data.exercisesFiles = [];
    const fileLength = files.length;
    for (const file of files) {
      const extention = file.name.split('.').pop();
      const uniqFilename = uniqid() + '.' + extention;
      if (extention === 'blend') {
        // 演習ファイルの場合ファイル名のまま保存してexercisesfilesに加える
        const uploadTask = storage
          .ref(`courses/lessons/${courseId}`)
          .child(file.name)
          .put(file);
        uploadTask.on(
          'state_changed',
          snapshot => {},
          error => {
            console.log(`error with upload${error}`);
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
              data.exercisesFiles.push(downloadURL);
              data.fileNames.push(file.name);
              if (data.urls.length + data.exercisesFiles.length === fileLength) {
                editLessonDatabase(courseId, lessonId, data);
              }
            });
          }
        );
      } else {
        const uploadTask = storage
          .ref(`courses/lessons/${courseId}`)
          .child(uniqFilename)
          .put(file);
        uploadTask.on(
          'state_changed',
          snapshot => {},
          error => {
            console.log(`error with upload${error}`);
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
              data.urls.push(downloadURL);
              data.fileNames.push(uniqFilename);
              if (data.urls.length + data.exercisesFiles.length === fileLength) {
                editLessonDatabase(courseId, lessonId, data);
              }
            });
          }
        );
      }
    }
  } else {
    editLessonDatabase(courseId, lessonId, data);
  }
};

const editCourseDatabase = async (courseId, data) => {
  const dateForISO = new Date();
  data.update_at = dateForISO.toISOString();
  await db
    .collection('courses')
    .doc(courseId)
    .update(data);
  console.log('Update Course Database');
};

export const editCourse = (courseId, eyecatchImage, productImage, data) => async dispatch => {
  if (eyecatchImage || productImage) {
    for (const imageName of data.imageNames) {
      // ファイル削除
      storage
        .ref('courses/courseImages')
        .child(imageName)
        .delete()
        .then(() => console.log(`delete ${imageName}`));
    }
    data.imageNames = [];
    data.eyecatchImageUrl = '';
    data.productImageUrl = '';
    let count = 1;
    // ファイルアップロード
    if (productImage) {
      count += 1;
      const extention = productImage.name.split('.').pop();
      const uniqFilename = uniqid() + '.' + extention;
      const uploadTask = storage
        .ref('courses/courseImages')
        .child(uniqFilename)
        .put(productImage);
      uploadTask.on(
        'state_changed',
        snapshot => {},
        error => {
          console.log(`error with upload${error}`);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            data.productImageUrl = downloadURL;
            data.imageNames.push(uniqFilename);
            if (data.imageNames.length === count) {
              editCourseDatabase(courseId, data);
            }
          });
        }
      );
    }
    const extention = eyecatchImage.name.split('.').pop();
    const uniqFilename = uniqid() + '.' + extention;
    const uploadTask = storage
      .ref('courses/courseImages')
      .child(uniqFilename)
      .put(eyecatchImage);
    uploadTask.on(
      'state_changed',
      snapshot => {},
      error => {
        console.log(`error with upload${error}`);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          data.eyecatchImageUrl = downloadURL;
          data.imageNames.push(uniqFilename);
          if (data.imageNames.length === count) {
            editCourseDatabase(courseId, data);
          }
        });
      }
    );
  } else {
    editCourseDatabase(courseId, data);
  }
};
